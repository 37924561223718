import { updateAuthTokensSuccess, updateAuthTokensError, updateAuthTokens } from '../defaultActions/tokenRefreshActions';
import { getDispatcherRedirectUrl } from '../../configuration';
import { refreshUserTokens } from '../defaultActions/userDataActions';
import { decode } from 'jsonwebtoken';
import * as UserLocalStorage from '../../utilities/UserLocalStorage/UserLocalStorage';
import * as TokenUtils from '../../utilities/Token/tokenUtils';
import * as AuthAPI from '../../data/AuthAPI';

/**
 * Middleware used to refresh expired user authentication tokens. Every Redux action dispatched will be intercepted by this middleware.
 * When the type of the intercepted action is "function" we can assume its an API call, so we check if the users JWT is expired. 
 * If so, dispatches an action to indicate the refresh request has started. Upon successful refresh, dispatches request success which updates
 * the store with the new tokens.
 * 
 * ex: https://medium.com/@meagle/understanding-87566abcfb7a
 * 
 * @export
 * @param {*} { dispatch, getState }
 * @returns
 */
export function authMiddleware({ dispatch, getState }) {
    return next => (action) => {
        if (typeof action === 'function') {
            const state = getState();

            if (TokenUtils.tokenIsExpired(state.userData.jwtExpirationTime)) {
                // dispatch that we are beginning the token refresh request process..
                dispatch(updateAuthTokens());

                refreshAuthTokens(state, dispatch);
            }
        }
        return next(action);
    };
}


/**
 * Calls the NoveListAuth API's refresh token endpoint with the provided refresh token. On success, updates localStorage and Redux store
 *
 * @export
 * @param {*} state
 * @param {*} dispatch
 */
function refreshAuthTokens(state, dispatch) {
    let refreshToken = state.userData.refreshToken;
    let userId = state.userData.userId;

    AuthAPI.getNewTokenFromRefresh(refreshToken, processRetryTokenResult(userId, dispatch))
}

function processRetryTokenResult(userId, dispatch) {
    return (request, xhr) => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
            let refreshData = getRefreshData(xhr);
            updateStore(dispatch, refreshData);
            updateLocalStorage(userId, refreshData);
        }
        else {
            dispatch(updateAuthTokensError());
            forceExpiredUserToReAuthenticate(userId);
        }
    }
}

function getRefreshData(xhr) {
    let authTokens = JSON.parse(xhr.responseText);
    authTokens.jwtExpirationTime = decode(authTokens.access_token).exp;

    return TokenUtils.getRefreshDataFromTokens(JSON.stringify(authTokens));
}

function updateStore(dispatch, refreshData) {
    dispatch(updateAuthTokensSuccess());
    dispatch(refreshUserTokens(refreshData));
}

function updateLocalStorage(userId, refreshData) {
    let current = UserLocalStorage.getSpecificDataForUserFromSharedAnalyticsNoFlag(userId, "userData");
    let localStorageData = { userData: Object.assign({}, current, refreshData) };

    UserLocalStorage.setSpecificDataToSharedAnalytics(userId, localStorageData);
}

/**
 * When the refresh token request fails (expired refresh_token or other issue) we need to redirect the user to the 
 * login screen so they can re-authenticate.
 * 
 * Clears local storage for the provided userId and redirects to the login screen. 
 *
 * @param {*} state
 */
function forceExpiredUserToReAuthenticate(userId) {
    UserLocalStorage.clearLocalStorageForUser(userId);
    window.location.assign(getDispatcherRedirectUrl());
}