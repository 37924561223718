import * as ActionTypes from '../../../dataStore/ActionTypes.js';
import InitialState from '../../../dataStore/InitialState';
import * as StatusIDs from '../../../data/DataStatusIDs';

export default function TotalViewsReducer(state = InitialState.totalView, action = {}) {
  switch (action.type) {
    case ActionTypes.LOAD_TOTAL_VIEW_SUCCESS:
      return Object.assign({}, state, { status: StatusIDs.SUCCESS, data: action.data });
    case ActionTypes.LOAD_TOTAL_VIEW_ERROR:
      return Object.assign({}, state, { status: StatusIDs.ERROR, data: action.data });
    case ActionTypes.RESET_TOTAL_VIEW:
        return Object.assign({}, state, { status: StatusIDs.LOADING, data: null });
    default:
      return state;
  }
}
