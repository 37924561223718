import React, { Component } from 'react';
import { Col, Row} from 'reactstrap';
import HeaderWithButton from '../../components/headers/HeaderWithButton';
import HeaderWithLine from '../../components/headers/HeaderWithLine';
import './SplashPage.css';

class SplashPageLearnMoreSection extends Component {
  render() {
    return (
        <div>
          <Col>
            <HeaderWithLine headerText="Learn More" />
          </Col>
          <Row className="splash-page-section-body">
            <Col>
                <HeaderWithButton 
                    headerText='Contact us to learn more about how we can help your readers discover more books.' 
                    buttonText ='Request Info' 
                    buttonLink='https://www.ebsco.com/novelist/request-information' 
                />

                <HeaderWithButton 
                    headerText='Check out our product demo to see why NoveList Select is the most reader-friendly catalog enrichment available.' 
                    buttonText ='See a Demo' 
                    buttonLink='https://www.ebsco.com/novelist/demos' 
                />
            </Col>
          </Row>
        </div>
    );
  }
}

export default SplashPageLearnMoreSection;