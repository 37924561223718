import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import CardWithButton from '../../components/cards/CardWithButton';
import HeaderWithLine from '../../components/headers/HeaderWithLine';
import './SplashPage.css';

class SplashPageSectionWithBoxes extends Component {
  render() {
    return (
        <div>
          <Col>
            <HeaderWithLine headerText="Understand how your community discovers books" />
          </Col>
          <Row className="splash-page-section-body">
            <Col>
              <CardWithButton 
                header="Reader engagement" 
                body="The Reader Engagement portion of the dashboard provides at-a-glance information for both broad measures of engagement like views as well as more focused measures like clicks on similar titles, series information, and other individual features." 
                buttonLink="https://www.ebsco.com/novelist/products/novelist-select/catalog-analytics-dashboard"
                buttonText="Learn More"
              />
            </Col>
            <Col>
              <CardWithButton 
                header="Reader insights" 
                body="The Reader Insights page provides detailed analysis of NoveList Select's views data with monthly lists of top titles, top authors, and top series browsed by your community." 
                buttonLink="https://www.ebsco.com/novelist/products/novelist-select/catalog-analytics-dashboard"
                buttonText="Learn More"
              />
            </Col>
            <Col>
              <CardWithButton 
                header="Expanded discovery" 
                body="The NoveList Select Analytics Dashboard provides platform-wide information about your patrons browsing habits in everything from your catalog integration to NoveList Select for Checkout" 
                buttonLink="https://www.ebsco.com/novelist/products/novelist-select"
                buttonText="Learn More"
              />
            </Col>
          </Row>
        </div>
    );
  }
}

export default SplashPageSectionWithBoxes;