import * as ActionTypes from '../../../dataStore/ActionTypes.js';
import InitialState from '../../../dataStore/InitialState';
import * as StatusIDs from '../../../data/DataStatusIDs';

export default function SystemMessageReducer(state =  InitialState.systemMessage,  action = {}) {
  switch (action.type) {
    case ActionTypes.LOAD_SYSTEM_MESSAGE_SUCCESS:
      return Object.assign({}, state, {status :StatusIDs.SUCCESS, ...action.data});
    case ActionTypes.LOAD_SYSTEM_MESSAGE_ERROR:
        return Object.assign({}, state,{status :StatusIDs.ERROR, ...action.data});
    default:
      return state;

  }
}
