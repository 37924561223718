import InitialState from "../InitialState";
import * as actionTypes from '../../dataStore/ActionTypes';

export default function expirationDateReducer(state = InitialState.expirationDate , action = {}){
    switch (action.type) {
        case actionTypes.SET_DATA_EXPIRATION_DATE:
            return action.expirationDate;
        default:
            return state;
    }
}
