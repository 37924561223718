import * as ActionTypes from '../../../dataStore/ActionTypes.js';
import InitialState from '../../../dataStore/InitialState';
import * as StatusIDs from '../../../data/DataStatusIDs';

export default function MonthlyTotalUsageByFeatureReducer(state = InitialState.monthlyTotalUsageByFeature, action = {}) {
  switch (action.type) {
    case ActionTypes.MONTHLY_TOTAL_USAGE_BY_FEATURE_SUCCESS:
      return Object.assign({}, state, { status: StatusIDs.SUCCESS, ...action.data });
    case ActionTypes.MONTHLY_TOTAL_USAGE_BY_FEATURE_ERROR:
      return Object.assign({}, state, { status: StatusIDs.ERROR, ...action.data });
    case ActionTypes.RESET_MONTHLY_TOTAL_USAGE_BY_FEATURE:
      return Object.assign({}, state, { status: StatusIDs.LOADING, data: null });
    default:
      return state;

  }
}