import React from 'react';
import './MultiUserDropDown.css';
import MultiUserDropdownItem from './MultiUserDropdownItem';
import MultiUserDropDownItemHeader from './MultiUserDropdownItemHeader';

const handleOnClick = (props, custId, custName) => {
    props.onClick(custId, custName)
}

const DropdownItemsWithHeader = (props) => {
    return (
        <div>
            <MultiUserDropDownItemHeader headerText={props.headerText} />
            <div className='scrollable-items'>
                {props.custList != null ? props.custList.map(c => {
                return <MultiUserDropdownItem customerNameComp={c.custName} key={c.custId} custId={c.custId}
                                isSelected={props.selectedCustId === c.custId} onClick={event => handleOnClick(props, c.custId, c.custName)}
                        />
                }) : null}
            </div>
        </div>
    );
}

export default DropdownItemsWithHeader;