import { setDataExpirationDate } from '../../dataStore/defaultActions/expirationDateActions';
import { setUserData } from '../../dataStore/defaultActions/userDataActions';
import { removeCookie } from '../Cookies/cookieUtilities';
import * as userLocalStorage from '../UserLocalStorage/UserLocalStorage';
import configureStore from '../../dataStore/store/ConfigureStore.js';
import * as configuration from '../../configuration';

const appStore = configureStore();

export function currentState()
{
    return appStore.getState();
}

export function initializeDataStore(userData) {
    appStore.dispatch(setUserData(userData));
    getAndfillDataforStore(appStore, userData);

    return appStore;
}

export function getAndFillForExpirationDate(store, userData) {
    let data = userLocalStorage.getSpecificDataForUserFromSharedAnalyticsNoFlag(userData.userId, "expirationDate");

    if (data === null) {
        let exDate = Date.now() + 14400000; // 4 hours
        store.dispatch(setDataExpirationDate(exDate));
        let localStorageDataForExDate = {
            "expirationDate": exDate
        };
        userLocalStorage.setSpecificDataToSharedAnalytics(userData.userId, localStorageDataForExDate);
    } else {
        store.dispatch(setDataExpirationDate(data));
    }
}

export function getAndfillDataforStore(store, userData) {
    let localStorageData = {
        "userData": userData
    };

    userLocalStorage.setSpecificDataToSharedAnalytics(userData.userId, localStorageData);
    userLocalStorage.setUserData(userData);
    removeCookie(configuration.tokenCookie);
    getAndFillForExpirationDate(store, userData);

    return store;
}