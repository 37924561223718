import * as DashboardAPI from '../../../data/DashboardAPI.js';
import * as types from '../../../dataStore/ActionTypes.js';
import { createInvertedChartDataObject } from '../../../utilities/Charts/ChartUtils.js';
import * as DateTimeUtils from '../../../utilities/DateTime/DateTimeUtils.js';
import * as UserLocalStorage from '../../../utilities/UserLocalStorage/UserLocalStorage';

export function loadTotalUsageByFeatureSuccess(totalUsageByFeature) {
  return {
    type: types.LOAD_TOTAL_USAGE_BY_FEATURE_SUCCESS, data:
    {
      data: totalUsageByFeature
    }
  };
}

export function loadTotalUsageByFeatureError() {
  return {
    type: types.LOAD_TOTAL_USAGE_BY_FEATURE_ERROR, data: {}
  };
}

export function loadTotalUsageByFeatureNoData() {
  return {
    type: types.LOAD_TOTAL_USAGE_BY_FEATURE_NODATA, data: {}
  };
}

export function resetTotalUsageByFeature() {
  return { type: types.RESET_TOTAL_USAGE_BY_FEATURE };
}

export function getTotalUsageByFeatureData() {
  return (dispatch, getState) => {
    let userData = getState().userData;


    retrieveData(dispatch, userData);

  };
}

function retrieveData(dispatch, userData) {
  let data = UserLocalStorage.getSpecificDataForUserFromSpecificCustomerAnalytics(userData.userId, userData.selectedCustId, "totalUsageByFeature");

    if (data === null) {
      let range = DateTimeUtils.getRangeForPreviousMonth();

    fetchTotalUsageByFeatureData(dispatch, userData, range.start, range.end);
  } else {
    dispatch(loadTotalUsageByFeatureSuccess(data));
  }
}

export function fetchTotalUsageByFeatureData(dispatch, userData, startDate, endDate) {
  return DashboardAPI.getDataWithDateRange('/clicks/byfeature', startDate, endDate, userData.accessToken, userData.selectedCustId)
    .then(totalUsageByFeature => {
      if(hasData(totalUsageByFeature))
      {
        let chartData = buildData(totalUsageByFeature, startDate, userData.selectedCustName);
        updateLocalStorage(userData.userId, chartData, userData.selectedCustId);
        dispatch(loadTotalUsageByFeatureSuccess(chartData));
      }
      else
      {
        dispatch(loadTotalUsageByFeatureNoData());
      }
    })
    .catch(error => {
      dispatch(loadTotalUsageByFeatureError());
    });
}

function hasData(totalUsageByFeature)
{
  return totalUsageByFeature.Result.length > 0 && totalUsageByFeature.Result[0].features.length > 0;
}

function buildData(totalUsageByFeature, startDate, libraryName) {
  let dateRange = DateTimeUtils.formatForDisplay(startDate);
  let chartData = createInvertedChartDataObject(totalUsageByFeature.Result[0], `Breakdown of clicks in ${dateRange}`, '');
  chartData.csvHeader = `Breakdown of clicks in ${dateRange}`;
  chartData.csvFileName = `${libraryName} Breakdown of NoveList Select clicks in ${dateRange}`;

  return chartData;
}

function updateLocalStorage(userId, totalUsageByFeature, selectedCustId) {
  let localStorageData = {
    totalUsageByFeature
  };

  UserLocalStorage.setSpecificDataToSpecificCustomerAnalytics(userId, selectedCustId, localStorageData);
}

export function generateUsageByFeatureCSV() {
  return (dispatch, getState) => {
  let state = getState();

  let csvData = state.totalUsageByFeature;

  if(csvData && csvData.data)
  {
    let libraryName = state.userData.selectedCustName;
    let payload = generateCsvPayload(libraryName, csvData.data);

    DashboardAPI.generateCsvReport('/BasicCSV', csvData.data.csvFileName, payload, state.userData.userToken);
  }
  };
}

export function generateCsvPayload(libraryName, csvData)
{
  let columnHeaders = getColumnHeaders();
  let rowData = getRowData(csvData.chartData);

  return {
      LibraryName: libraryName,
      ChartHeader: csvData.csvHeader,
      FileName: csvData.csvFileName,
      ColumnHeaders: columnHeaders,
      RowData: rowData,
  };
}

function getColumnHeaders()
{
  return ["Feature","Count"];
}

function getRowData(usageByFeatureData)
{
  return usageByFeatureData.labels.map((x, i) => [x, usageByFeatureData.datasets[0].data[i].toString()]);
}
