import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import DropdownItemsWithHeader from './DropdownItemsWithHeader';
import './MultiUserDropDown.css'

const RenderSystemTotals = (props) => {
    if (props.custList.length > 1) {
        let allOrg = [{
                custId: 'ALL ORGANIZATIONS',
                custName: 'ALL ORGANIZATIONS',
                isSelected: false
            }];

        return (
            <DropdownItemsWithHeader headerText={"System Totals"} custList={allOrg} onClick={props.onClick} selectedCustId={props.selectedCustId}/>
        );
    }
}

const RenderParentOrg = (props) => {
    let parentOrg = [{
        custId: props.parentCustId,
        custName: props.parentCustName,
        isSelected: false
    }];

    return (
        <DropdownItemsWithHeader headerText={props.custList.length > 1 ? "Parent Organization": "My Organization"} custList={parentOrg} onClick={props.onClick} selectedCustId={props.selectedCustId}/>
    );
}

const RenderIndividualOrgs = (props) => {
    if (props.custList.length > 1) {
        return (
            <DropdownItemsWithHeader headerText={"Individual Organizations"} custList={props.custList} onClick={props.onClick} selectedCustId={props.selectedCustId}/>
        );
    }
}

const MultiUserDropdownMenu = (props) => {
    return (
        <DropdownMenu right className="multi-user-dropdown-container">
                {RenderSystemTotals(props)}
                {RenderParentOrg(props)}
                {RenderIndividualOrgs(props)}
                <DropdownItem divider/>
                <DropdownItem className="clickable dropdown-item-centered" onClick={props.logoutOnclick}><i className="fa fa-lock"></i>  Logout</DropdownItem>
        </DropdownMenu>
    );
}

MultiUserDropdownMenu.propTypes = {
    logoutOnclick: PropTypes.func
}

export default MultiUserDropdownMenu;