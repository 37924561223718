import * as DashboardAPI from '../../../data/DashboardAPI.js';
import * as types from '../../../dataStore/ActionTypes.js';
import * as DateTimeUtils from '../../../utilities/DateTime/DateTimeUtils.js';
import * as UserLocalStorage from '../../../utilities/UserLocalStorage/UserLocalStorage';

export function loadTopViewedAuthorsSuccess(topViewedAuthors) {
	return {
		type: types.LOAD_TOP_VIEWED_AUTHORS_SUCCESS, data:
		{
			data: topViewedAuthors
		}
	};
}

export function loadTopViewedAuthorsError() {
	return {
		type: types.LOAD_TOP_VIEWED_AUTHORS_ERROR, data: {}
	};
}

export function loadTopViewedAuthorsNoData() {
	return {
		type: types.LOAD_TOP_VIEWED_AUTHORS_NODATA, data: {}
	};
}

export function resetTopViewedAuthors() {
	return {
	  type: types.RESET_TOP_VIEWED_AUTHORS
	};
  }

export function getTopViewedAuthorsData() {
	return (dispatch, getState) => {
		let userData = getState().userData;

		retrieveData(dispatch, userData);
	};
}

function retrieveData(dispatch, userData) {
	let data = UserLocalStorage.getSpecificDataForUserFromSpecificCustomerAnalytics(userData.userId, userData.selectedCustId, "topViewedAuthors");

	if (data === null) {
		let range = DateTimeUtils.getRangeForPreviousMonth();

		fetchTopViewedAuthorsData(dispatch, userData, range.start, range.end);
	} else {
		dispatch(loadTopViewedAuthorsSuccess(data));
	}
}

export function fetchTopViewedAuthorsData(dispatch, userData, startDate, endDate) {
	return DashboardAPI.getDataWithDateRange('/authors/TopViewed', startDate, endDate, userData.accessToken, userData.selectedCustId)
		.then(topViewedAuthors => {
			if (hasData(topViewedAuthors)) {
				let tableData = buildData(topViewedAuthors, startDate, userData.selectedCustName);
				updateLocalStorage(userData.userId, userData.selectedCustId, tableData);
				dispatch(loadTopViewedAuthorsSuccess(tableData));
			}
			else {
				dispatch(loadTopViewedAuthorsNoData());
			}
		})
		.catch(error => {
			dispatch(loadTopViewedAuthorsError());
		});
}

function hasData(topViewedAuthors) {
	return topViewedAuthors.Result.length > 0 && topViewedAuthors.Result[0].authors.length > 0;
}

function buildData(topViewedAuthors, startDate, libraryName) {
	let dateRange = DateTimeUtils.formatForDisplay(startDate);

	return {
		tableData: topViewedAuthors.Result[0].authors,
		title: `Top Authors in ${dateRange}`,
		subTitle: '',
		csvHeader: `Top Authors in ${dateRange}`,
		csvFileName: `${libraryName} Top Authors ${dateRange}`
	};
}

function updateLocalStorage(userId, selectedCustId, topViewedAuthors) {
	let localStorageData = {
		topViewedAuthors
	};

	UserLocalStorage.setSpecificDataToSpecificCustomerAnalytics(userId, selectedCustId, localStorageData);
}

export function generateTopViewedAuthorsCSV() {
    return (dispatch, getState) => {
		let state = getState();

		let csvData = state.topViewedAuthors;

		if(csvData && csvData.data)
		{
			let libraryName = state.userData.selectedCustName;
			let payload = generateCsvPayload(libraryName, csvData.data);

			DashboardAPI.generateCsvReport('/BasicCSV', csvData.data.csvFileName, payload, state.userData.userToken);
		}
    };
}

export function generateCsvPayload(libraryName, csvData)
{
    let columnHeaders = getColumnHeaders();
    let rowData = getRowData(csvData.tableData);

    return {
        LibraryName: libraryName,
        ChartHeader: csvData.csvHeader,
        FileName: csvData.csvFileName,
        ColumnHeaders: columnHeaders,
        RowData: rowData,
    };
}

function getColumnHeaders()
{
    return ["Author","Count"];
}

function getRowData(authorData)
{
    return authorData.map(x => [x.author,x.count.toString()])
}
