import React, { Component } from 'react'
import { connect } from 'react-redux';
import { MessageBar } from '../../../components/alerts/MessageBar'
import { getSystemMessageData } from './SystemMessageActions';


export class SystemMessageContainer extends Component {
	componentDidMount() {
        this.props.getSystemMessageData();
	}

	render() {
		return (
			<MessageBar messageToRender={this.props.systemMessage} />
		);
    }
}

export function mapStateToProps(state) {
	return {
		systemMessage: state.systemMessage.data,
		status: state.systemMessage.status,
	};
}

export function mapDispatchToProps(dispatch) {
	return {
		getSystemMessageData: () => {
			dispatch(getSystemMessageData());
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessageContainer) 