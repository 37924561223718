import React from 'react';
import './HeaderWithLine.css'

const HeaderWithLine = (props) => {
	return (
        <div>
            <h1 className="header-with-line">{props.headerText}</h1>
            <hr />
        </div>
	)
};


export default HeaderWithLine
