import React from 'react';
import { DropdownItem, Row, Col } from 'reactstrap';
import './MultiUserDropDown.css';

const renderAggregatePill = (customerNameComp) => {
    if (customerNameComp === 'ALL ORGANIZATIONS') {
        return (
            <span className="badge rounded-pill bg-info">Aggregate</span>
        );
    }
} 


const MultiUserDropdownItem = (props) => {
    return (
        <DropdownItem disabled={props.isSelected} onClick={props.onClick} className={props.isSelected ? 'selected-dropdown-item' : ''}>
            <Row className="user-dropdown-item">
                <Col xs="12" md="12" >
                    <span className='user-dropdown-item-name'>{props.customerNameComp}</span>
                    {renderAggregatePill(props.customerNameComp)}
                </Col>
            </Row>
        </DropdownItem>
    );
}

export default MultiUserDropdownItem;