import React, { Component } from 'react';
import { Col, Row} from 'reactstrap';
import HeaderWithButton from '../../components/headers/HeaderWithButton';
import HeaderWithLine from '../../components/headers/HeaderWithLine';
import './SplashPage.css';

class SplashPageStayInTheLoopSection extends Component {
  render() {
    return (
        <div>
          <Col>
            <HeaderWithLine headerText="Stay in the Loop" />
          </Col>
          <Row className="splash-page-section-body">
            <Col>
                <HeaderWithButton 
                    headerText="Get tips on readers' advisory and library marketing, sign up for webinars, and find information on NoveList product updates."
                    buttonText ='Read the Latest' 
                    buttonLink='https://www.ebsco.com/blogs/novelist' 
                />
            </Col>
          </Row>
        </div>
    );
  }
}

export default SplashPageStayInTheLoopSection;
