import * as DashboardAPI from '../../../data/DashboardAPI.js';
import * as types from '../../../dataStore/ActionTypes.js';
import * as UserLocalStorage from '../../../utilities/UserLocalStorage/UserLocalStorage';
import * as DashboardLogging from '../../../utilities/Logging/DashboardLogging';
import * as CustChangeActions from '../../../CustChangeActions';

export function loadMultiUserDropDownSuccess(multiUserDropdown) {
  return {
    type: types.LOAD_MULTI_USER_DROPDOWN_SUCCESS, data:
    {
      data: multiUserDropdown
    }
  };
}

export function getMultiUserDropDownData() {
  return (dispatch, getState) => {
    let userData = getState().userData;

    retrieveData(dispatch, userData);
  };
}

function retrieveData(dispatch, userData) {
  let data = UserLocalStorage.getSpecificDataForUserFromSharedAnalytics(userData.userId, "multiUserDropdown");

  if (data === null) {
    fetchMultiUserDropDownData(dispatch, userData);
  } else {
    dispatch(loadMultiUserDropDownSuccess(data));
  }
}

export function fetchMultiUserDropDownData(dispatch, userData) {
  return getChildSiteData(userData).then((custList) => {
    dispatch(loadMultiUserDropDownSuccess(custList));
  })
}

export function getChildSiteData(userData) {
  let data = {
    custList: [],
    parentCustId: userData.custId,
    parentCustName: userData.custName,
    selectedCustId: userData.custId
  };
  return DashboardAPI.getChildSites(userData.accessToken)
    .then(multiUserDropdown => {
      if(hasData(multiUserDropdown))
      {
        data.custList = sortDropdownAlphabetically(multiUserDropdown).map(cust => {
          return {
            custId: cust.custId,
            custName: cust.custName,
            isSelected: false
          }
        });
        if (data.custList.length > 1){
          data.selectedCustId = 'ALL ORGANIZATIONS'
        }
        updateLocalStorage(userData.userId, data);
        return data;
      }
      else
      {
        return data;
      }
    })
    .catch(() => {
      return data;
    });
}

export function sortDropdownAlphabetically(custList){
  return custList.sort((a, b) => a.custName.localeCompare(b.custName));
}

function hasData(multiUserDropdown)
{
  return multiUserDropdown.length > 0;
}

function updateLocalStorage(userId, multiUserDropdown) {
  let localStorageData = {
    multiUserDropdown
  };

  UserLocalStorage.setSpecificDataToSharedAnalytics(userId, localStorageData);
}

export function selectedCustomerChanged(newCustId, newCustName){
  return (dispatch, getState) => {
    let userData = getState().userData;
    let dropDownData = getState().multiUserDropdown.data;

    DashboardLogging.logUserSelected(userData, newCustId, newCustName);
    dropDownData.selectedCustId = newCustId;

    dispatch(loadMultiUserDropDownSuccess(dropDownData));
    dispatch(CustChangeActions.updateForNewCustId(newCustId, newCustName));
    dispatch(DashboardLogging.logPageLoad(window.location.hash.replace('#','')));
  }
}