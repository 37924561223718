import moment from 'moment'

export function getRangeForThisMonth(origin = moment()) {
    return getRangeForPreviousNMonthsInclusive(1, origin);
}

export function getRangeForPreviousMonth(origin = moment()) {
    return getRangeForPreviousNMonths(1, origin);
}

export function getRangeStartOfPreviousTwoYearToPreviousMonth(origin = moment()) {
    let end = origin.clone().add(-1, 'months').endOf('month');
    let start = end.clone().add(-2, 'years').startOf('year');

    return {
        start, end
    }
}

export function getRangeForPreviousNMonthsInclusive(nMonths, origin = moment()) {
    let end = origin.clone().endOf('month');
    let start = end.clone().add(-1 * (nMonths - 1), 'months').startOf('month');

    return {
        start, end
    }
}

export function getRangeForPreviousNMonths(nMonths, origin = moment()) {
    let end = origin.clone().add(-1, 'months').endOf('month');
    let start = end.clone().add(-1 * (nMonths - 1), 'months').startOf('month');

    return {
        start, end
    }
}

export function formatForUri(date) {
    return moment(date).format('YYYY-MM-DD');
}

export function formatForDisplay(date) {
    return moment(date).format('MMMM YYYY');
}

export function formatAsFullDate(date) {
    return moment(date).format('MMMM D, YYYY');
}

export function formatForFileName(date) {
    return moment(date).format('YYYYMMDD');
};

export function formatWithSlashes(date) {
    return moment(date).format('M/D/YYYY');
};

export function formatWithMonthAbbreviated(date) {
    return moment(date).format('MMM YYYY');
}

export const getCurrentUnixTimestamp = () => {
    return moment().unix();
}