export const PrimaryBranding = "primary";
export const SuccessBranding = "success";
export const InfoBranding = "info";
export const WarningBranding = "warning";
export const DangerBranding = "danger";
export const NovOrange = "novorange";
export const NovGreen = "novgreen";
export const NovPurple = "novpurple";
export const NovBlue = "novblue";
export const NovGray = "novgray";
export const LineChartPurple = 'linechartpurple';
export const LineChartColorScheme = [NovBlue, NovGreen, LineChartPurple];
export const SkeletonPrimaryColor = "skeletonprimarycolor";
export const SkeletonSecondaryColor = "skeletonsecondarycolor";

const brandingSet = {
    "primary": '#20a8d8',
    "success": '#4dbd74',
    "info": '#63c2de',
    "warning": '#ffc107',
    "danger": '#f86c6b',
    "novorange": '#f19427',
    "novgreen": '#7fc241',
    "novpurple": '#947eb3',
    "novblue": '#44c6ea',
    "novgray": '#808284',
    "linechartpurple": '#8162b2',
    "skeletonprimarycolor": "#f3f3f3",
    "skeletonsecondarycolor": "#ecebeb"
}

export function GetBrandingColorByName(brandingName) {
    return brandingSet[brandingName.toLowerCase()];
}

export function mapSeverityToCssClass(severity){
    switch (severity) {
      case "Success":
        return "success";
      case "Information":
        return "info";
      case "Warning":
        return "warning";
      case "Error":
        return "danger";
      default:
        return "";
    }
  }