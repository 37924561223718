import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import CustomerName from './CustomerName';

export class CustomerNameContainer extends Component {
	render() {
		return <CustomerName custName={this.props.custName} />
	}
}

CustomerNameContainer.propTypes = {
	custName: PropTypes.string.isRequired
}

export function mapStateToProps(state) {
	return {
		custName: state.userData.selectedCustName || 'MY LIBRARY'
	};
}

export default connect(mapStateToProps)(CustomerNameContainer)
