import React, { Component } from 'react';
import { AppNavbarBrand } from '@coreui/react';
import logo from '../../assets/img/brand/novelist-logo.png';
import logoSmall from '../../assets/img/brand/novelist-logo-sm.jpg';
import LoginButton from '../../components/buttons/LoginButton';

class SplashPageHeader extends Component {
  render() {
    return (
      <React.Fragment>
        <AppNavbarBrand 
          full={{ src: logo, width: 150, height: 19, alt: 'NoveList Logo' }}
          minimized={{ src: logoSmall, width: 30, height: 30, alt: 'NoveList Logo' }}
        />
        <LoginButton />
      </React.Fragment>
    );
  }
}

export default SplashPageHeader;
