import * as ActionTypes from '../../../dataStore/ActionTypes.js';
import InitialState from '../../../dataStore/InitialState';
import * as StatusIDs from '../../../data/DataStatusIDs';

export default function ViewsOverTimeReducer(state =  InitialState.viewsOverTime,  action = {}) {
  switch (action.type) {
    case ActionTypes.LOAD_VIEWS_OVER_TIME_SUCCESS:
      return Object.assign({}, state, {status :StatusIDs.SUCCESS, ...action.data});
    case ActionTypes.LOAD_VIEWS_OVER_TIME_ERROR:
        return Object.assign({}, state,{status :StatusIDs.ERROR, ...action.data});
    case ActionTypes.LOAD_VIEWS_OVER_TIME_NODATA:
        return Object.assign({}, state,{status :StatusIDs.NODATA, ...action.data});
    case ActionTypes.RESET_VIEWS_OVER_TIME:
        return Object.assign({}, state, { status: StatusIDs.LOADING, data: null });
    default:
      return state;

  }
}
