import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import './HeaderWithButton.css';

const HeaderWithButton = (props) => {
	return (
        <Row>
            <Col md='8'>
                <div className="header-with-button-text">{props.headerText}</div>
            </Col>
            <Col>
                <Button className="novelist-button" target="_blank" href={props.buttonLink}>{props.buttonText}</Button>
            </Col>
        </Row>
	)
};


export default HeaderWithButton
