const configurationPlaceholderRegex = RegExp('^.{.+}$');  //Any character (should be a pound sign) + { + 1 or more characters + }

const DASHBOARD_SERVER_BASE_URI = getConfigurationValue('#{DASHBOARD_SERVER_BASE_URI}', 'http://localhost:3000');

const DASHBOARD_API_SERVER_BASE_URI = getConfigurationValue('#{DASHBOARD_API_SERVER_BASE_URI}', 'https://localhost:44388');

const AUTH_SERVER_BASE_URI = getConfigurationValue('#{AUTH_SERVER_BASE_URI}', 'https://localhost:44325');

const DISPATCHER_BASE_URI = getConfigurationValue('#{DISPATCHER_BASE_URI}', 'https://logon.devqa.ebsco.zone');

const AUTH_CLIENT_ID = getConfigurationValue('#{AUTH_CLIENT_ID}', 'hQ9fzxA2mOQAnx3RMCmn0krGYc262gQd');

const GOOGLE_TRACKING_ID = getConfigurationValue('#{GOOGLE_TRACKING_ID}', 'G-FQ99DNLKSZ');

export const tokenCookie = 'NSA_DASHBOARD';
export const sessionCookie = 'NSA_DASHBOARD_SESSION_ID';
export const splashPageWidgetUrl = "//www.libraryaware.com/Widgets/Show/c27d6863-eb76-4571-8f87-c1dd3ee7dfcd";

export function getGoogleTrackingId() {
    return GOOGLE_TRACKING_ID;
}

export function getApiUri() {
    return DASHBOARD_API_SERVER_BASE_URI;
}

export function getDispatcherRedirectUrl() {
    return `${DISPATCHER_BASE_URI}/api/dispatcher/oauth/authorize?client_id=${AUTH_CLIENT_ID}&response_type=code&`
        + `scope=openid+affiliation+role+profile+email+offline_access&prompt=login&acr_values=auth0&`
        + `redirect_uri=${AUTH_SERVER_BASE_URI}/auth/code?finalRedirect=${DASHBOARD_SERVER_BASE_URI}`;
}

export const refreshTokenRequest = {
    endPointUrl: () => {
        return `${AUTH_SERVER_BASE_URI}/auth/token`;
    },
    createRequest: (refreshToken) => {
        let requestBody = {
            refresh_token: refreshToken
        }

        return {
            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                "Content-Type": "application/json"
            }
        }
    }
};

/**
 * Helper function to obtain the proper configuration value. 
 * NOTE: This is a hack-y way to work around the way we are using Octopus environment variables
 * The idea is that anytime you want to get config, you call this function, where the first param is the 
 * Octopus environment variable string and the second is a "default" value to use.
 * 
 * If we're running locally, the first parameter will be the Octopus string which starts with a "#" char.
 * If "original" has been transformed by OD, then it will fail the regex and we will use that value,
 * Else we use the defaultValue provided
 *
 * @export
 * @param {*} original The Octopus environment variable, starting with the "#" character
 * @param {*} defaultValue A default value to use when running locally 
 * @returns
 */
export function getConfigurationValue(original, defaultValue) {
    if (valueWasTransformedByOctopus(original)) {
        return original;
    }
    else {
        return defaultValue;
    }
}

function valueWasTransformedByOctopus(value) {
    return !configurationPlaceholderRegex.test(value);
}

/**
 * Used to retrieve a boolean value out of configuration. This is due to Octopus transforming values into strings. 
 * 
 *
 * @param {*} original
 * @param {*} defaultValue
 * @returns
 */
export function getBooleanConfigurationValue(original, defaultValue) {
    let result = getConfigurationValue(original, defaultValue);

    if (typeof (result) === "boolean") {
        return result;
    } else if (result === 'true') {
        return true;
    } else {
        return false;
    }
}