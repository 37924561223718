import * as StatusIds from '../data/DataStatusIDs';

export default {
  totalClick: {
    status: StatusIds.LOADING,
    data: null
  },
  totalView: {
    status: StatusIds.LOADING,
    data: null
  },
  totalFeatureUsage: {
    status: StatusIds.LOADING,
    data: null
  },
  viewsOverTime: {
    status: StatusIds.LOADING,
    data: null
  },
  totalUsageByFeature: {
    status: StatusIds.LOADING,
    data: null
  },
  topViewedTitles: {
    status: StatusIds.LOADING,
    data: null
  },
  topViewedAuthors: {
    status: StatusIds.LOADING,
    data: null
  },
  topViewedSeries: {
    status: StatusIds.LOADING,
    data: null
  },
  monthlyTotalUsageByFeature: {
    status: StatusIds.LOADING,
    data: null
  },
  expirationDate: null,
  userData: {
    userId: '',
    userToken: '',
    userName: '',
    jwtExpirationTime: '',
    selectedCustId: '',
    selectedCustName: ''
  },
  refreshTokenRequestStatus: {
    isLoading: false,
    error: false
  },
  systemMessage: {
    status: 0,
    data: null
  },
  featureFlags: {
    data: {}
  },
  multiUserDropdown: {
    data: null
  }
};
