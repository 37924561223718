import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, DropdownToggle} from 'reactstrap';
import { sessionCookie } from '../../../configuration';
import { removeCookie } from '../../../utilities/Cookies/cookieUtilities';
import MultiUserDropdownMenu from '../../../components/menus/MulitUserDropDown/MultiUserDropdownMenu';
import CustomerNameContainer from '../../../features/Misc/CustomerName/CustomerNameContainer'
import '../../../components/menus/MulitUserDropDown/MultiUserDropDown.css';
import { getMultiUserDropDownData, selectedCustomerChanged } from './MultiUserDropDownActions';


export class MultiUserDropdownContainer extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
        };
    }

    componentDidMount() {
		if (this.shouldGetData()) {
			this.props.getMultiUserDropDownData();
		}
	}

	shouldGetData() {
		return this.props.data.custList === null;
	}

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    }

    handleLogout() {
        removeCookie(sessionCookie);
        window.location.href = "/";
    }

    render() {
        return (
            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle nav caret>
                    <div className='mutli-user-dropdown'>
                        <CustomerNameContainer />
                    </div>
                </DropdownToggle>

                <MultiUserDropdownMenu 
                    customerNameComp={<CustomerNameContainer />}
                    logoutOnclick={this.handleLogout}
                    parentCustId={this.props.data.parentCustId}
                    parentCustName={this.props.data.parentCustName}
                    selectedCustId={this.props.data.selectedCustId}
                    custList={this.props.data.custList}
                    onClick={this.props.onClick}
                />

            </Dropdown>
        );
    }
}

export function mapStateToProps(state) {
	return {
		data: state.multiUserDropdown.data
	};
}

export function mapDispatchToProps(dispatch) {
	return {
		getMultiUserDropDownData: () => {
			dispatch(getMultiUserDropDownData());
        },
        onClick: (newCustId, newCustName) => {
			dispatch(selectedCustomerChanged(newCustId, newCustName));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiUserDropdownContainer);
