import * as ActionTypes from '../../../dataStore/ActionTypes.js';
import InitialState from '../../../dataStore/InitialState';

export default function MultiUserDropDownReducer(state =  InitialState.multiUserDropdown,  action = {}) {
  switch (action.type) {
    case ActionTypes.LOAD_MULTI_USER_DROPDOWN_SUCCESS:
      return Object.assign({}, state, {...action.data});
    default:
      return state;

  }
}
