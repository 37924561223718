import React, { Component } from 'react';
import { Nav, NavItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/novelist-logo.png';
import logoSmall from '../../assets/img/brand/novelist-logo-sm.jpg';
import MultiUserDropDownContainer from '../../features/Menus/MultiUserDropDown/MultiUserDropDownContainer';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  render() {

    const renderMultiSelectDropDown = () => {
      return (
        <Nav className="ml-auto" navbar>  
          <NavItem  className="multi-dropdown-header">
            <MultiUserDropDownContainer />
          </NavItem>
        </Nav>
      );
    }

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand 
          full={{ src: logo, width: 150, height: 19, alt: 'NoveList Logo' }}
          minimized={{ src: logoSmall, width: 30, height: 30, alt: 'NoveList Logo' }}
        />

        <AppSidebarToggler className="d-md-down-none" display="lg" />

        {renderMultiSelectDropDown()}

      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
