import React from 'react';
import Loadable from 'react-loadable'
import DefaultLayout from './layouts/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

const ReaderInsights = Loadable({
  loader: () => import('./views/ReaderInsights/ReaderInsights'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard/Dashboard'),
  loading: Loading,
});

const HelpCenter = Loadable({
  loader: () => import('./views/Help/Help'),
  loading: Loading,
});

// This object defines the sites page hierarchy and structure
// pages: array of objects where each object represents a page of the site
//  |---> navigation: defines the navigation used by the <AppSidebarNav>
export const siteSettings = {
  pages: [
    {
      name: 'Home',
      isEnabled: true,
      route: { path: '/', exact: true, name: 'Home', component: DefaultLayout }
    },
    {
      name: 'Reader Engagement',
      isEnabled: true,
      route: { path: '/dashboard', name: 'Reader Engagement', component: Dashboard },
      navigation: {
        displayInSideNav: true,
        name: 'Reader Engagement',
        url: '/dashboard',
        icon: 'icon-speedometer'
      },
      features: []
    },
    {
      name: 'Reader Insights',
      isEnabled: true,
      route: { path: '/readerinsights', name: 'Reader Insights', component: ReaderInsights },
      navigation: {
        displayInSideNav: true,
        name: 'Reader Insights',
        url: '/readerinsights',
        icon: 'nav-icon fa fa-book'
      },
      features: []
    },
    {
      name: 'Help Center',
      isEnabled: true,
      route: { path: '/help', name: 'Help Center', component: HelpCenter },
      navigation: {
        displayInSideNav: true,
        name: 'Help Center',
        url: '/help',
        icon: 'icon-info'
      },
      features: []
    }
  ]
}


/**
 * Gets the enabled routes from the site settings object. 
 *
 * @export
 * @param {*} siteSettings
 * @returns An array of route objects.
 */
export function getEnabledRoutes(siteSettings) {
  if (!siteSettings) {
    return null;
  }

  let enabledRoutes = [];

  siteSettings.pages.forEach(element => {
    if (element.isEnabled) {
      enabledRoutes.push(element.route);
    }
  });

  return enabledRoutes;
}


/**
 * Filters out the side navigation items from the site settings object.
 *
 * @export
 * @param {*} siteSettings
 * @returns An object containing an array of items to be displayed in the side navigation menu.
 */
export function getEnabledSideNavigationItems(siteSettings) {
  if (!siteSettings) {
    return null;
  }

  let enabledSideNavItems = {
    items: []
  };

  siteSettings.pages.forEach(element => {
    if (element.isEnabled && element.navigation && element.navigation.displayInSideNav === true) {
      enabledSideNavItems.items.push(element.navigation);
    }
  });

  return enabledSideNavItems;
}
