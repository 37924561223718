import { Button } from "reactstrap";

import React from "react";
import './LoginButton.css';
import { getDispatcherRedirectUrl } from "../../configuration";

const login = () => {
    window.location.assign(getDispatcherRedirectUrl());
}

const LoginButton = () => {
    return (
        <div className='login-button'>
            <Button className='novelist-button' onClick={() => login()}>Login</Button>
        </div>
    );
}

export default LoginButton;