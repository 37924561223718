import React from 'react';
import {Helmet} from "react-helmet";
import * as Configuration from '../../configuration';

const GoogleAnalytics = () => {

  return (
      <Helmet>
        <script src={`https://www.googletagmanager.com/gtag/js?id=${Configuration.getGoogleTrackingId()}`} async={true} />
        <script type="text/javascript">
          {
           `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${Configuration.getGoogleTrackingId()}');`
          }
        </script>
      </Helmet>
  );
}

export default GoogleAnalytics;
