import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import HomeCarousel from './HomeCarousel';
import './SplashPage.css';
import SplashPageLearnMoreSection from './SplashPageLearnMoreSection';
import SplashPageSectionWithBoxes from './SplashPageSectionWithBoxes';
import SplashPageSocialSection from './SplashPageSocialSection';
import SplashPageStayInTheLoopSection from './SplashPageStayInTheLoopSection';

class SplashPage extends Component {
  render() {
    return (
      <div className="splash-page">
        <Row className="splash-page-section">
          <Col>
            <HomeCarousel />
          </Col>
        </Row>
        <Row className="splash-page-section">
          <Col>
            <SplashPageSectionWithBoxes />
          </Col>
        </Row>
        <Row className="splash-page-section">
          <Col>
            <SplashPageSocialSection />
          </Col>
        </Row>
        <Row className="splash-page-section">
          <Col>
            <SplashPageLearnMoreSection />
          </Col>
        </Row>
        <Row className="splash-page-section">
          <Col>
            <SplashPageStayInTheLoopSection />
          </Col>
        </Row>
      </div>
    );
  }
}

export default SplashPage;