import * as configuration from '../../configuration';


/**
 *returns the cookie with the specified key
 *
 * @export
 * @param {*} key - cookie key
 * @returns
 */
export function getCookie(key) {
  let name = key + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookies = decodedCookie.split(';');
  for (let index = 0; index < cookies.length; index++) {
    let cookie = cookies[index].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}



/**
 * removes cookie from domain by setting the expire date to a date that has already passed.
 *
 * @export
 * @param {*} key - cookie key
 */
export function removeCookie(key) {
    if (getCookie(key) !== ''){
        document.cookie = key + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

/**
 * creates a cookie and sets it based on the name, value, and time in minutes.
 *
 * @export
 * @param {*} name - name of the cookie
 * @param {*} value - value of that cookie
 * @param {*} expiration - time before the cookie expires in minutes.
 */
export function setCookie(name, value, expiration) {
  let expires = "";
    
  if (expiration) {
    let date = new Date();
    date.setTime(date.getTime() + (expiration * 60000));
    expires = `expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${(value || "")}; ${expires};`;
}

export function setSessionID(id){
  setCookie(configuration.sessionCookie, id, 1440);
}

export function getSessionID(){
  return getCookie(configuration.sessionCookie);
}