import React from 'react';
import { Container } from 'reactstrap';
import {
  AppAside,
  AppFooter,
  AppHeader,
} from '@coreui/react';
// routes and sidebar nav config
import DefaultAside from '../DefaultLayout/DefaultAside';
import DefaultFooter from '../DefaultLayout/DefaultFooter';
import SplashPage from '../../features/SplashPage/SplashPage';
import SplashPageHeader from './SplashPageHeader';
import GoogleAnalytics from '../../features/Google/GoogleAnalytics';

const SplashPageLayout = () => {
  return (
    <div className="app">
      <GoogleAnalytics />
      <AppHeader fixed>
        <SplashPageHeader />
      </AppHeader>
      <div className="app-body">
        <main className="main">
          <Container fluid>
            <SplashPage />
          </Container>
        </main>
        <AppAside fixed>
          <DefaultAside />
        </AppAside>
      </div>
      <AppFooter>
        <DefaultFooter />
      </AppFooter>
    </div>
  );
}

export default SplashPageLayout;
