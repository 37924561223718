import InitialState from "../InitialState";
import * as ActionTypes from '../../dataStore/ActionTypes';

export default function userDataReducer(state = InitialState.userData, action = {}) {
    switch (action.type) {
        case ActionTypes.SET_USER_DATA:
            return action.userData;
        case ActionTypes.REFRESH_USER_TOKENS:
            return Object.assign({}, state, action.refreshData);
        case ActionTypes.UPDATE_SELECTED_CUST:
            return Object.assign({}, state, action.data);
        default:
            return state;
    }
}
