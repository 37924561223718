import { refreshTokenRequest } from '../configuration';

export function getNewTokenFromRefresh(refreshToken, processResult)
{
    let refreshTokenEndpoint = refreshTokenRequest.endPointUrl();
    let request = refreshTokenRequest.createRequest(refreshToken);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", refreshTokenEndpoint, false);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onreadystatechange = function () {
        processResult(this, xhr);
    };
    xhr.send(request.body);
}