/**
 * This utility class is used to store user analytics data in the browser's local storage
 * for a set period of time. The API calls to fetch and aggregate user analytic data are expensive, so we
 * only want to perform them once. Once we have the data in local storage we can use it for a set period of
 * time to populate the Redux store.
 */

export const LOCALSTORAGE_USER_ANALYTICS_DATA_KEY = 'nov-dashboard-user-analytics';
export const LOCALSTORAGE_USER_AUTH_KEY = 'nov-dashboard-user-auth';

/**
 * Checks if browser localStorage contains valid, non-stale data for for the provided userId.
 *
 * @param {String} userId The unique identifier associated with the logged in User.
 * @returns True if localStorage contains valid, non-expired data other returns False.
 */
export function hasValidData(userId) {
  let result = false;
  let data = getSharedAnalyticsDataForUser(userId);
  if (data && data.userData)
  {
    result = Date.now() < parseInt(data.expirationDate);
  }
  return result;
}

/**
 *Returns user analytics and profile data for the provided userId.
 *
 * @param {String} userId The unique identifier associated with the logged in User.
 * @returns {Object} An object containing user analytics and profile data.
 */
export function getSharedAnalyticsDataForUser(userId) {
  return JSON.parse(localStorage.getItem(`${LOCALSTORAGE_USER_ANALYTICS_DATA_KEY}-${userId}`));
}

export function getSpecificDataForUserFromSharedAnalytics(userId, dataProperty) {
  let userData = getSharedAnalyticsDataForUser(userId);

  if(objectHasProperty(userData, dataProperty))
  {
      return userData[dataProperty];
  }

  return null;
}

export function getSpecificDataForUserFromSharedAnalyticsNoFlag(userId, dataProperty) {
  let userData = getSharedAnalyticsDataForUser(userId);

  return objectHasProperty(userData, dataProperty) ? userData[dataProperty] : null;
}

function getSpecificCustomerAnalyticsDataForUser(userId, custId) {
  return JSON.parse(localStorage.getItem(`${LOCALSTORAGE_USER_ANALYTICS_DATA_KEY}-${userId}-${custId}`));
}

export function getSpecificDataForUserFromSpecificCustomerAnalytics(userId, custId, dataProperty) {
  let userData = getSpecificCustomerAnalyticsDataForUser(userId, custId);

  if(objectHasProperty(userData, dataProperty))
  {
      return userData[dataProperty];
  }

  return null;
}

function objectHasProperty(obj, dataProperty) {
  return (obj && obj[dataProperty] !== undefined);
}

/**
 * TODO: Deprecate.  This is only used by tests.
 * 
 * Places the provided data into browser localStorage. This data will be provided to the Redux store
 * and the analytics data is considered "fresh" until the provided expirationDate.
 *
 * @param {String} userId The unique identifier associated with the logged in User.
 * @param {Object} data An object containing user analytics data and other data which will be set in the Redux store.
 * IMPORTANT: The analytics data should be contained in an object whose key is named "analyticsData"
 * The user data should be contained in an object whose key is named "userData"
 * @param {Date} expirationDate The expiration date to set for the provided analytics data. NOTE: Use the javascript Date type.
 */
export function setDataForUser(userId, data, expirationDate) {
  if (data) {
    data.expirationDate = expirationDate;

    let analyticsDataKey = `${LOCALSTORAGE_USER_ANALYTICS_DATA_KEY}-${userId}`;
    let userDataKey = `${LOCALSTORAGE_USER_AUTH_KEY}-${userId}`;

    localStorage.setItem(analyticsDataKey, JSON.stringify(data));
    localStorage.setItem(userDataKey, JSON.stringify(data.userData));
  }
}


export function setSpecificDataToSharedAnalytics(userId, data) {
  if (data) {
    let analyticsDataKey = `${LOCALSTORAGE_USER_ANALYTICS_DATA_KEY}-${userId}`;

    let oldData = getSharedAnalyticsDataForUser(userId);

    if (oldData === null){
      oldData = {};
    }

    let newData = Object.assign({}, oldData, data);
    localStorage.setItem(analyticsDataKey, JSON.stringify(newData));
  }
}

export function setSpecificDataToSpecificCustomerAnalytics(userId, custId, data) {
  if (data) {
    let analyticsDataKey = `${LOCALSTORAGE_USER_ANALYTICS_DATA_KEY}-${userId}-${custId}`;

    let oldData = getSpecificCustomerAnalyticsDataForUser(userId, custId);

    if (oldData === null){
      oldData = {};
    }

    let newData = Object.assign({}, oldData, data);
    localStorage.setItem(analyticsDataKey, JSON.stringify(newData));
  }
}

/**
 * Removes the user specific auth data from localStorage. When a user logs out of the Dashboard,
 * we should ONLY remove their session specific data. The costly analytics data can remain in localStorage
 * until it's expiration date.
 *
 * @param {String} userId The unique identifier associated with the logged in User.
 */
export function destroyUserAuthData(userId) {
  let userDataKey = `${LOCALSTORAGE_USER_AUTH_KEY}-${userId}`;

  localStorage.removeItem(userDataKey);
}

export function destroyUserAnalyticsData(userId) {
  let analyticsDataKey = `${LOCALSTORAGE_USER_ANALYTICS_DATA_KEY}-${userId}`;
  
  let keysToRemove = [];

  for (let i = 0; i < localStorage.length; i++)  
  {
    let key = localStorage.key(i);
    if(key.startsWith(analyticsDataKey))
    {
      keysToRemove.push(key);
    }
  }

  for(let i = 0; i < keysToRemove.length; i++)
  {
    localStorage.removeItem(keysToRemove[i]);
  }
}

export function clearLocalStorageForUser(userId){
  destroyUserAuthData(userId);
  destroyUserAnalyticsData(userId);
}

export function setUserData(userData) {
  if (userData)
  {
    let userDataKey = `${LOCALSTORAGE_USER_AUTH_KEY}-${userData.userId}`;
    localStorage.setItem(userDataKey, JSON.stringify(userData));
  }
}
