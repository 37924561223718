import * as DashboardAPI from '../../../data/DashboardAPI.js';
import * as types from '../../../dataStore/ActionTypes.js';
import * as DateTimeUtils from '../../../utilities/DateTime/DateTimeUtils.js';
import * as UserLocalStorage from '../../../utilities/UserLocalStorage/UserLocalStorage';

export function loadTotalClicksSuccess(totalClick) {
  return {
    type: types.LOAD_TOTAL_CLICK_SUCCESS, data: totalClick
  };
}

export function loadTotalClicksError() {
  return { type: types.LOAD_TOTAL_CLICK_ERROR, data: {} };
}

export function resetTotalClicks() {
  return { type: types.RESET_TOTAL_CLICK };
}

export function getTotalClickData() {
  return (dispatch, getState) => {
    let userData = getState().userData;

    retrieveData(dispatch, userData);

  };
}

function retrieveData(dispatch, userData) {
  let data = UserLocalStorage.getSpecificDataForUserFromSpecificCustomerAnalytics(userData.userId, userData.selectedCustId, "totalClick");

  if (data === null) {
    let range = DateTimeUtils.getRangeForPreviousMonth();

    fetchTotalClicksData(dispatch, userData, range.start, range.end);
  } else {
    dispatch(loadTotalClicksSuccess(data));
  }
}

export function fetchTotalClicksData(dispatch, userData, startDate, endDate) {
  return DashboardAPI.getDataWithDateRange('/clicks/total', startDate, endDate, userData.accessToken, userData.selectedCustId)
    .then(totalClick => {
      let data = buildData(totalClick, startDate, endDate);
      dispatch(loadTotalClicksSuccess(data));
      updateLocalStorage(userData.userId, userData.selectedCustId, data);
    })
    .catch(error => {
      dispatch(loadTotalClicksError());
    });
}

function buildData(totalClick, startDate, endDate) {
  return {
    count: totalClick.Result.length > 0 ? totalClick.Result[0].count : 0,
    startDate, endDate
  };
}

function updateLocalStorage(userId, selectedCustId, totalClick) {
  let localStorageData = {
    totalClick
  };

  UserLocalStorage.setSpecificDataToSpecificCustomerAnalytics(userId, selectedCustId, localStorageData);
}
