import { getSharedAnalyticsDataForUser, hasValidData } from '../UserLocalStorage/UserLocalStorage';
import { getCookie } from '../Cookies/cookieUtilities';
import { decode } from 'jsonwebtoken';
import * as configuration from '../../configuration';
import { getCurrentUnixTimestamp } from '../DateTime/DateTimeUtils';
import * as DashboardLogging from '../Logging/DashboardLogging';
import sha256 from 'crypto-js/sha256';

/**
 * Returns the userData object for a given userId
 * First checks to see if there is localstorage data for a given userId.
 * If there is not, then it will use what is present in the tokenCookie.
 * @param {int} userId 
 */
export function getUserDataById(userId) {
    let userData = '';

    if (hasValidData(userId)) {
        userData = getSharedAnalyticsDataForUser(userId).userData;
    } else {
        userData = getUserDataFromTokens(getCookie(configuration.tokenCookie));

        //Getting data from the cookie indicates an initial log in so we log the event here.
        if(Object.keys(userData).length > 0) {
            DashboardLogging.logUserLogin(userData);
        }
    }

    return userData;
}

/**
 * Checks that our userData object has the required fields: userId and custId
 *  userId is required to lookup information from local storage
 *  custId is required to make API calls that get data out of Amplitude
 * @param {object} userData 
 */
export function userDataHasRequiredFields(userData) {
    return userData && userData.userId && userData.custId;
}

export function getUserDataFromTokens(authTokensString) {
    let userData = {};

    if (authTokensString) {
        let userAuthData = JSON.parse(decodeURI(authTokensString));
        let identityToken = decode(userAuthData.id_token);
        let accessToken = decode(userAuthData.access_token);

        userData = {
            userId: getUserIdFromToken(identityToken),
            custName: userAuthData.custName === '' ? 'MY LIBRARY' : userAuthData.custName,
            userToken: userAuthData.id_token,
            familyName: identityToken.family_name,
            givenName: identityToken.given_name,
            userEmail: identityToken.email,
            jwtExpirationTime: accessToken.exp,
            accessToken: userAuthData.access_token,
            refreshToken: userAuthData.refresh_token,
            custId: userAuthData.custId,
            selectedCustId: userAuthData.custId,
            selectedCustName: userAuthData.custName === '' ? 'MY LIBRARY' : userAuthData.custName
        };
    }

    return userData;
}

export function getRefreshDataFromTokens(authTokensString) {
    let userData = {};

    if (authTokensString) {
        let userAuthData = JSON.parse(decodeURI(authTokensString));
        let accessToken = decode(userAuthData.access_token);

        userData = {
            userToken: userAuthData.id_token,
            jwtExpirationTime: accessToken.exp,
            accessToken: userAuthData.access_token,
            refreshToken: userAuthData.refresh_token,
        };
    }

    return userData;
}

export function tokenIsExpired(jwtExpirationTime) {
    return jwtExpirationTime <= getCurrentUnixTimestamp();
}

function getUserIdFromToken(identityToken){
    let userId = identityToken.sub;

    if (userId.startsWith('support-') && !userId.includes('@')){
        return userId;
    }else {
        return sha256(userId).toString();
    }
}