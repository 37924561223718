import * as ActionTypes from '../../../dataStore/ActionTypes.js';
import InitialState from '../../../dataStore/InitialState';
import * as StatusIDs from '../../../data/DataStatusIDs';

export default function TopViewedTitlesReducer(state =  InitialState.topViewedTitles,  action = {}) {
  switch (action.type) {
    case ActionTypes.LOAD_TOP_VIEWED_TITLES_SUCCESS:
      return Object.assign({}, state, {status :StatusIDs.SUCCESS, ...action.data});
    case ActionTypes.LOAD_TOP_VIEWED_TITLES_ERROR:
        return Object.assign({}, state,{status :StatusIDs.ERROR, ...action.data});
    case ActionTypes.LOAD_TOP_VIEWED_TITLES_NODATA:
        return Object.assign({}, state,{status :StatusIDs.NODATA, ...action.data});
    case ActionTypes.RESET_TOP_VIEWED_TITLES:
        return Object.assign({}, state, { status: StatusIDs.LOADING, data: null });
    default:
      return state;
  }
}
