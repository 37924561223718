import * as actionTypes from '../ActionTypes';


/**
 * Sends a payload to the store containing the refreshed access tokens.
 * @param {Object} authTokens An object containing the new auth tokens
 */
export function updateAuthTokensSuccess(authTokens){
    return {
        type: actionTypes.FETCHING_REFRESH_TOKEN_SUCCESS,
        data: authTokens
    };
}



/**
 *  Used to signal the reducer that the refresh token request has failed.
 */
export function updateAuthTokensError(){
    return {
        type: actionTypes.FETCHING_REFRESH_TOKEN_FAILURE,
    };
}


/**
 * Used to signal the store that a token refresh request has begun.
 */
export function updateAuthTokens(){
    return {
        type: actionTypes.FETCHING_REFRESH_TOKEN
    };
}