import * as DashboardAPI from '../../../data/DashboardAPI.js';
import * as types from '../../../dataStore/ActionTypes.js';
import { createChartDataForTotalFeatureUsage } from '../../../utilities/Charts/ChartUtils.js';
import * as DateTimeUtils from '../../../utilities/DateTime/DateTimeUtils.js';
import * as UserLocalStorage from '../../../utilities/UserLocalStorage/UserLocalStorage';

export function loadTotalFeatureUsageSuccess(totalFeatureUsage) {
  return {
    type: types.LOAD_TOTAL_FEATURE_USAGE_SUCCESS, data:
    {
      data: totalFeatureUsage
    }
  };
}

export function loadTotalFeatureUsageError() {
  return { type: types.LOAD_TOTAL_FEATURE_USAGE_ERROR, data: {} };
}

export function loadTotalFeatureUsageNoData() {
  return { type: types.LOAD_TOTAL_FEATURE_USAGE_NODATA, data: {} };
}

export function resetTotalFeatureUsage() {
  return { type: types.RESET_TOTAL_FEATURE_USAGE };
}

export function getTotalFeatureUsageData() {
  return (dispatch, getState) => {
    let userData = getState().userData;

    retrieveData(dispatch, userData);
  };
}

function retrieveData(dispatch, userData) {
  let data = UserLocalStorage.getSpecificDataForUserFromSpecificCustomerAnalytics(userData.userId, userData.selectedCustId, "totalFeatureUsage");

  if (data === null) {
    let range = DateTimeUtils.getRangeStartOfPreviousTwoYearToPreviousMonth();

    fetchTotalFeatureUsageData(dispatch, userData, range.start, range.end);
  } else {
    dispatch(loadTotalFeatureUsageSuccess(data));
  }
}

export function fetchTotalFeatureUsageData(dispatch, userData, startDate, endDate) {
  return DashboardAPI.getDataWithDateRange('/clicks/total', startDate, endDate, userData.accessToken, userData.selectedCustId)
    .then(totalFeatureUsage => {
      if(hasData(totalFeatureUsage))
      {
        let data = buildData(totalFeatureUsage, startDate, endDate, userData.selectedCustName);
        updateLocalStorage(userData.userId, userData.selectedCustId, data);
        dispatch(loadTotalFeatureUsageSuccess(data));
      }
      else
      {
        dispatch(loadTotalFeatureUsageNoData());
      }
    })
    .catch(error => {
      dispatch(loadTotalFeatureUsageError());
    });
}

function hasData(totalFeatureUsage)
{
  return totalFeatureUsage.Result.length > 0 && totalFeatureUsage.Result.map(x => x.count).reduce((prev, next) => prev + next) > 0
}

function buildData(totalFeatureUsage, startDate, endDate, libraryName) {
  let dateRange = `${DateTimeUtils.formatAsFullDate(startDate)} - ${DateTimeUtils.formatAsFullDate(endDate)}`;
  let title = `Clicks from ${dateRange}`;
  let csvFileName = `${libraryName} NoveList Select Clicks ${DateTimeUtils.formatWithMonthAbbreviated(startDate)} to ${DateTimeUtils.formatWithMonthAbbreviated(endDate)}`;
  let csvHeader = `Clicks from ${dateRange}`
  return createChartDataForTotalFeatureUsage(totalFeatureUsage.Result, title, '', csvFileName, csvHeader);
}

function updateLocalStorage(userId, custId, totalFeatureUsage) {
  let localStorageData = {
    totalFeatureUsage
  };

  UserLocalStorage.setSpecificDataToSpecificCustomerAnalytics(userId, custId, localStorageData);
}

export function generateTotalFeatureUsageCSV() {
  return (dispatch, getState) => {
  let state = getState();

  let csvData = state.totalFeatureUsage;

  if(csvData && csvData.data)
  {
    let libraryName = state.userData.selectedCustName;
    let payload = generateCsvPayload(libraryName, csvData.data);

    DashboardAPI.generateCsvReport('/BasicCSV', csvData.data.csvFileName, payload, state.userData.userToken);
  }
  };
}

export function generateCsvPayload(libraryName, csvData)
{
  let columnHeaders = getColumnHeaders();
  let rowData = getRowData(csvData.chartData);

  return {
      LibraryName: libraryName,
      ChartHeader: csvData.csvHeader,
      FileName: csvData.csvFileName,
      ColumnHeaders: columnHeaders,
      RowData: rowData,
  };
}

function getColumnHeaders()
{
  return [
    "", "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
}

function getRowData(chartData)
{
  return chartData.datasets.map(x => getRowForDataSet(x));
}

function getRowForDataSet(dataSet)
{
  let result = [dataSet.label.replace(" Year To Date", "")]

  dataSet.data.map(x => result.push(x.toString()));

  return result;
}
