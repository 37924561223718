import React, { Component } from 'react'
import { UncontrolledAlert } from 'reactstrap';
import PropTypes from 'prop-types'

export class MessageBar extends Component {
	render() {
        if (HasValidMessage(this.props.messageToRender)){
            return (
                <UncontrolledAlert color={this.props.messageToRender.displayClass}>
                    {this.props.messageToRender.message}
                </UncontrolledAlert>
            );
        }else {
            return null;
        }
    }
}

function HasValidMessage(messageToRender){
    if (messageToRender)
    {
        if (messageToRender.message && messageToRender.displayClass)
        {
            return true;
        }
    }
    return false;
}

MessageBar.propTypes = {
	messageToRender: PropTypes.object
}

export default MessageBar