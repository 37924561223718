import {
  combineReducers
} from 'redux';
import TotalClicksReducer from '../../features/Statistics/TotalClicks/TotalClicksReducer';
import TotalViewsReducer from '../../features/Statistics/TotalViews/TotalViewsReducer';
import TotalFeatureUsageReducer from '../../features/Charts/TotalFeatureUsage/TotalFeatureUsageReducer';
import TotalUsageByFeatureReducer from '../../features/Charts/UsageByFeature/TotalUsageByFeatureReducer';
import MonthlyTotalUsageByFeatureReducer from '../../features/Statistics/MonthlyClickReport/MonthlyTotalUsageByFeatureReducer';
import TopViewedTitlesReducer from '../../features/Tables/TopViewedTitles/TopViewedTitlesReducer';
import TopViewedAuthorsReducer from '../../features/Tables/TopViewedAuthors/TopViewedAuthorsReducer';
import TopViewedSeriesReducer from '../../features/Tables/TopViewedSeries/TopViewedSeriesReducer';
import expirationDateReducer from './expirationDateReducer';
import userDataReducer from './userDataReducer';
import tokenRefreshReducer from './tokenRefreshReducer';
import SystemMessageReducer from '../../features/Alerts/SystemMessage/SystemMessageReducer';
import FeatureFlagsReducer from '../../FeatureFlagsReducer';
import MultiUserDropDownReducer from '../../features/Menus/MultiUserDropDown/MultiUserDropDownReducer';
import ViewsOverTimeReducer from '../../features/Charts/ViewsOverTime/ViewsOverTimeReducer';

const rootReducer = combineReducers({
  totalClick: TotalClicksReducer,
  totalView: TotalViewsReducer,
  totalFeatureUsage: TotalFeatureUsageReducer,
  viewsOverTime: ViewsOverTimeReducer,
  totalUsageByFeature: TotalUsageByFeatureReducer,
  topViewedTitles: TopViewedTitlesReducer,
  topViewedAuthors: TopViewedAuthorsReducer,
  topViewedSeries: TopViewedSeriesReducer,
  expirationDate: expirationDateReducer,
  userData: userDataReducer,
  monthlyTotalUsageByFeature: MonthlyTotalUsageByFeatureReducer,
  refreshTokenRequestStatus: tokenRefreshReducer,
  systemMessage: SystemMessageReducer,
  featureFlags: FeatureFlagsReducer,
  multiUserDropdown: MultiUserDropDownReducer
});

export default rootReducer;
