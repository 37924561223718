import * as config from '../configuration.js';
import * as DateTimeUtils from '../utilities/DateTime/DateTimeUtils';

let download = require('downloadjs');


/**
 * This function creates a  call the dashboard.api based on the endpoints you send to it.
 *
 * @export
 * @param {*} endpoint - the endpoint of the url you want to call. EX: total/clicks
 * @param {*} startDate - the starting date of data
 * @param {*} endDate - the ending date of data
 * @returns
 */
export function getDataWithDateRange(endpoint, startDate, endDate, token, requestedCustId) {
  const url = `${config.getApiUri()}/dashboard-api/analytics`;
  let fullEndpoint = url + endpoint + `?startDate=${DateTimeUtils.formatForUri(startDate)}&endDate=${DateTimeUtils.formatForUri(endDate)}`;

  if(requestedCustId){
    fullEndpoint = fullEndpoint + `&requestedCustId=${requestedCustId}`;
  }

    return fetch(fullEndpoint, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    .then(function (response) {
        return response.json();
    })
    .catch(function () {
    });
}

export function logEvent(eventData, token) {
    const url = `${config.getApiUri()}/dashboard-api/logging`;

    return fetch(url, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(eventData)
    })
    .catch(function () {
    });
}

export function generateCsvReport(endpoint, fileName, data, token) {
    const url = `${config.getApiUri()}/dashboard-api/reports`;
    let fullEndpoint = url + endpoint;
    return fetch(fullEndpoint, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": `Bearer ${token}`
        }
    })
    .then(function (response) {
        if (response.ok) {
            return response.blob();
        }
    })
    .then(function (blob) {
        if (blob != null) {
            download(blob, `${fileName}.csv`, 'text/csv');
        } else {
            //TODO: inform user of error. possibly like below.
            //alert('Oops, Something Went Wrong!');
        }
    });
}

export function getSystemSettings(token) {
    const url = `${config.getApiUri()}/dashboard-api/settings`;
    return fetch(url, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    .then(function (response) {
        return response.json();
    })
    .catch(function () {
    });
}

export async function getChildSites(token) {
    const url = `${config.getApiUri()}/dashboard-api/sites/children`;

    return fetch(url, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })
    .then(function (response) {
        return response.json();
    })
    .catch(function () {
        return {};
    });
}
