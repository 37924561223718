import { resetTotalViews } from './features/Statistics/TotalViews/TotalViewActions';
import { resetTotalClicks } from './features/Statistics/TotalClicks/TotalClickActions';
import { resetMonthlyTotalUsageByFeature } from './features/Statistics/MonthlyClickReport/MonthlyTotalUsageByFeatureActions';
import { resetTotalFeatureUsage } from './features/Charts/TotalFeatureUsage/TotalFeatureUsageActions';
import { resetTotalUsageByFeature } from './features/Charts/UsageByFeature/TotalUsageByFeatureActions';
import { resetTopViewedAuthors } from './features/Tables/TopViewedAuthors/TopViewedAuthorsActions';
import { resetTopViewedSeries } from './features/Tables/TopViewedSeries/TopViewedSeriesActions';
import { resetTopViewedTitles } from './features/Tables/TopViewedTitles/TopViewedTitlesActions';
import { resetViewsOverTime } from './features/Charts/ViewsOverTime/ViewsOverTimeActions';
import * as ActionTypes from './dataStore/ActionTypes';

export function updateSelectedCust(selectedCustId, selectedCustName)
{
    return {
        type: ActionTypes.UPDATE_SELECTED_CUST,
        data: {
            selectedCustId, selectedCustName
        }
    };
}

export function updateForNewCustId(selectedCustId, selectedCustName) {
	return (dispatch, getState) => {
        dispatch(updateSelectedCust(selectedCustId, selectedCustName));
        dispatch(resetTotalViews());
        dispatch(resetTotalClicks());
        dispatch(resetMonthlyTotalUsageByFeature());
        dispatch(resetTotalFeatureUsage());
        dispatch(resetTotalUsageByFeature());
        dispatch(resetTopViewedAuthors());
        dispatch(resetTopViewedSeries());
        dispatch(resetTopViewedTitles());
        dispatch(resetViewsOverTime())
    };
}