import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { HashRouter, Switch } from 'react-router-dom';
import ProtectedRoute from './features/Routes/ProtectedRoute.js';
import * as StoreUtils from './utilities/Store/StoreUtils.js';
import * as OnLoadActions from './OnLoadActions';
import './App.scss';
import * as MultiUserDropdownActions from './features/Menus/MultiUserDropDown/MultiUserDropDownActions';
import * as CustChangeActions from './CustChangeActions';

// Layouts
import { DefaultLayout } from './layouts';

class App extends Component {

	constructor(props) {
		super(props);
		this.store = StoreUtils.initializeDataStore(this.props.userData, this.props.featureFlags);

		this.store.dispatch(OnLoadActions.loadFeatureFlags(this.props.featureFlags));

		this.store.dispatch(MultiUserDropdownActions.loadMultiUserDropDownSuccess(this.props.custData));
		if (this.props.custData.custList.length > 1){
			this.store.dispatch(CustChangeActions.updateForNewCustId('ALL ORGANIZATIONS', 'ALL ORGANIZATIONS'));
		}
	}

	render() {
		return (
			<Provider store={this.store}>
				<HashRouter>
					<Switch>
						<ProtectedRoute path='/' name="Home" component={DefaultLayout} />
					</Switch>
				</HashRouter>
			</Provider>
		);
	}
}

export default App;
