import React from 'react';
import './privacyNotice.css';

const PrivacyNotice = () => {
    let divider = <span> | </span>;

    return (
        <div className='privacy-notice-container'>
            <a id='supportSite' className='privacy-notice-link' href='https://connect.ebsco.com/' target='_blank' rel="noopener noreferrer">EBSCO Support Site</a>{divider}
            <a id='privacyPolicy' className='privacy-notice-link' href='https://www.ebsco.com/company/privacy-policy' target='_blank' rel="noopener noreferrer">Privacy Policy</a>{divider}
            <a id='termsOfUser' className='privacy-notice-link' href='https://www.ebsco.com/terms-of-use' target='_blank' rel="noopener noreferrer">Terms of Use</a>{divider}
            <a id='gdpr' className='privacy-notice-link' href='https://www.ebsco.com/gdpr' target='_blank' rel="noopener noreferrer">GDPR</a>
            <br />
            <span id='copyrightStatement'>© 2020 EBSCO Industries, Inc. All rights reserved.</span>
        </div>
    );
}

export default PrivacyNotice;