import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { getDispatcherRedirectUrl } from '../../configuration';
import * as DashboardLogging from '../../utilities/Logging/DashboardLogging';

export class ProtectedRoute extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.logPageLoad(this.props.location);
    }
  }

  render() {
    if (this.props.isAuthenticated === true) {
      return (
        <Route path='/' name="Home" component={this.props.component} />
      );
    } else {
      return (
        <Route path='' component={() => { window.location.href = getDispatcherRedirectUrl(); return ''; }} />
      );
    }

  }
}

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool
}

export function mapStateToProps(state) {
  return {
    isAuthenticated: isAuthenticated(state.expirationDate)
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    logPageLoad: (location) => {
			dispatch(DashboardLogging.logPageLoad(location.pathname));
		}
  };
}

function isAuthenticated(expirationDate) {
  let currentTime = new Date().getTime();
  return (expirationDate >= currentTime);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute))
