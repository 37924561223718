import * as DashboardAPI from '../../../data/DashboardAPI.js';
import * as types from '../../../dataStore/ActionTypes.js';
import * as DateTimeUtils from '../../../utilities/DateTime/DateTimeUtils.js';
import * as UserLocalStorage from '../../../utilities/UserLocalStorage/UserLocalStorage';

export function loadTopViewedTitlesSuccess(topViewedTitles) {
  return {
    type: types.LOAD_TOP_VIEWED_TITLES_SUCCESS, data:
    {
      data: topViewedTitles
    }
  };
}

export function loadTopViewedTitlesError() {
  return {
    type: types.LOAD_TOP_VIEWED_TITLES_ERROR, data: {}
  };
}

export function loadTopViewedTitlesNoData() {
  return {
    type: types.LOAD_TOP_VIEWED_TITLES_NODATA, data: {}
  };
}

export function resetTopViewedTitles() {
	return {
	  type: types.RESET_TOP_VIEWED_TITLES
	};
  }

export function getTopViewedTitlesData() {
  return (dispatch, getState) => {
    let userData = getState().userData;

    retrieveData(dispatch, userData);
  };
}

function retrieveData(dispatch, userData) {
  let data = UserLocalStorage.getSpecificDataForUserFromSpecificCustomerAnalytics(userData.userId, userData.selectedCustId, "topViewedTitles");

    if (data === null) {
      let range = DateTimeUtils.getRangeForPreviousMonth();

    fetchTopViewedTitlesData(dispatch, userData, range.start, range.end);
  } else {
    dispatch(loadTopViewedTitlesSuccess(data));
  }
}

export function fetchTopViewedTitlesData(dispatch, userData, startDate, endDate) {
  return DashboardAPI.getDataWithDateRange('/titles/TopViewed', startDate, endDate, userData.accessToken, userData.selectedCustId)
    .then(topViewedTitles => {
      if(hasData(topViewedTitles))
      {
        let tableData = buildData(topViewedTitles, startDate, userData.selectedCustName);
        updateLocalStorage(userData.userId, userData.selectedCustId, tableData);
        dispatch(loadTopViewedTitlesSuccess(tableData));
      }
      else
      {
        dispatch(loadTopViewedTitlesNoData());
      }
    })
    .catch(error => {
      dispatch(loadTopViewedTitlesError());
    });
}

function hasData(topViewedTitles)
{
  return topViewedTitles.Result.length > 0 && topViewedTitles.Result[0].titles.length > 0;
}

function buildData(topViewedTitles, startDate, libraryName) {
  let dateRange = DateTimeUtils.formatForDisplay(startDate);

  return {
    tableData: topViewedTitles.Result[0].titles,
    title: `Top Titles in ${dateRange}`,
    subTitle: '',
    csvHeader: `Top Titles in ${dateRange}`,
		csvFileName: `${libraryName} Top Titles ${dateRange}`
  };
}

function updateLocalStorage(userId, selectedCustId, topViewedTitles) {
  let localStorageData = {
    topViewedTitles
  };

  UserLocalStorage.setSpecificDataToSpecificCustomerAnalytics(userId, selectedCustId, localStorageData);
}


export function generateTopViewedTitlesCSV() {
  return (dispatch, getState) => {
  let state = getState();

  let csvData = state.topViewedTitles;

  if(csvData && csvData.data)
  {
    let libraryName = state.userData.selectedCustName;
    let payload = generateCsvPayload(libraryName, csvData.data);

    DashboardAPI.generateCsvReport('/BasicCSV', csvData.data.csvFileName, payload, state.userData.userToken);
  }
  };
}

export function generateCsvPayload(libraryName, csvData)
{
  let columnHeaders = getColumnHeaders();
  let rowData = getRowData(csvData.tableData);

  return {
      LibraryName: libraryName,
      ChartHeader: csvData.csvHeader,
      FileName: csvData.csvFileName,
      ColumnHeaders: columnHeaders,
      RowData: rowData,
  };
}

function getColumnHeaders()
{
  return ["Title","Author","Count"];
}

function getRowData(titleData)
{
  return titleData.map(x => [x.title,x.author,x.count.toString()])
}
