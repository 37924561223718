import * as DashboardAPI from '../../../data/DashboardAPI';
import * as types from '../../../dataStore/ActionTypes.js';
import * as DateTimeUtils from '../../../utilities/DateTime/DateTimeUtils.js';
import * as UserLocalStorage from '../../../utilities/UserLocalStorage/UserLocalStorage';

export function loadTotalViewsSuccess(totalView) {
  return {
    type: types.LOAD_TOTAL_VIEW_SUCCESS, data: totalView
  };
}

export function loadTotalViewsError() {
  return {
    type: types.LOAD_TOTAL_VIEW_ERROR, data:
    {
    }
  };
}

export function resetTotalViews() {
  return {
    type: types.RESET_TOTAL_VIEW
  };
}

export function getTotalViewData() {
  return (dispatch, getState) => {
    let userData = getState().userData;

    retrieveData(dispatch, userData);
  };
}

function retrieveData(dispatch, userData) {
  let data = UserLocalStorage.getSpecificDataForUserFromSpecificCustomerAnalytics(userData.userId, userData.selectedCustId, "totalView");

  if (data === null) {
    let range = DateTimeUtils.getRangeForPreviousMonth();

    fetchTotalViewsData(dispatch, userData, range.start, range.end);
  } else {
    dispatch(loadTotalViewsSuccess(data));
  }
}

export function fetchTotalViewsData(dispatch, userData, startDate, endDate) {
  return DashboardAPI.getDataWithDateRange('/views/total', startDate, endDate, userData.accessToken, userData.selectedCustId).then(totalView => {
    let data = buildData(totalView, startDate, endDate);
    dispatch(loadTotalViewsSuccess(data));
    updateLocalStorage(userData.userId, userData.selectedCustId, data);
  })
    .catch(error => {
      dispatch(loadTotalViewsError());
    });
}

function buildData(totalView, startDate, endDate) {
  return {
    count: totalView.Result.length > 0 ? totalView.Result[0].count : 0,
    startDate, endDate
  };
}

function updateLocalStorage(userId, selectedCustId, totalView) {
  let localStorageData = {
    totalView
  };

  UserLocalStorage.setSpecificDataToSpecificCustomerAnalytics(userId, selectedCustId, localStorageData);
}
