import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Button } from 'reactstrap';
import './CardWithButton.css'

const CardWithButton = (props) => {
	return (
		<Card className="card-with-button">
            <CardHeader className="card-with-button-header">{props.header}</CardHeader>
            <CardBody className="card-with-button-body">
                <p className="card-body-text">{props.body}</p>
            </CardBody>
            <CardFooter>
                <Button className="novelist-button" target="_blank" href={props.buttonLink}>{props.buttonText}</Button>
            </CardFooter>
        </Card>
	)
};


export default CardWithButton
