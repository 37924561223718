import React from 'react'
import PropTypes from 'prop-types'
import './CustomerName.css';
import { Row, Col } from 'reactstrap';


const renderAggregatePill = (customerNameComp) => {
    if (customerNameComp === 'ALL ORGANIZATIONS') {
        return (
            <span className="badge rounded-pill bg-info aggregate-pill">Aggregate</span>
        );
    }
} 

const CustomerName = (props) => {
	return (
		<Row className="d-md-down-none customer-container">
			<Col xs="12" md="12">
				<span className='customer-name'>{props.custName}</span>
				{renderAggregatePill(props.custName)}
			</Col>
		</Row>
	)
}

CustomerName.propTypes = {
	custName: PropTypes.string.isRequired
}

export default CustomerName