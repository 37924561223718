import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// routes and sidebar nav config
import { getEnabledRoutes, siteSettings, getEnabledSideNavigationItems } from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import SystemMessageContainer from '../../features/Alerts/SystemMessage/SystemMessageContainer';


class DefaultLayout extends Component {
  constructor() {
    super();
    this.filteredNavigation = getEnabledSideNavigationItems(siteSettings);
    this.filteredRoutes = getEnabledRoutes(siteSettings);
  }
  render() {
    return (
        <div className="app">
          <AppHeader fixed>
            <DefaultHeader />
          </AppHeader>
          <div className="app-body">
            <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              <AppSidebarNav navConfig={this.filteredNavigation} {...this.props} />
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
            <main className="main">
              <AppBreadcrumb appRoutes={this.filteredRoutes}/>
              <Container fluid>
                <SystemMessageContainer />
                <Switch>
                  {this.filteredRoutes.map((route, idx) => {
                      if (route.component) {
                        return (
                          <Route 
                            key={idx}
                            path={route.path} 
                            exact={route.exact}
                            name={route.name}
                            render={props => (<route.component {...props} />)}
                          />)
                      }
                      return (null);
                    }
                  )}
                  <Redirect from="/" to="/dashboard" />
                </Switch>
              </Container>
            </main>
            <AppAside fixed>
              <DefaultAside />
            </AppAside>
          </div>
          <AppFooter>
            <DefaultFooter />
          </AppFooter>
        </div>
    );
  }
}

export default DefaultLayout;
