import * as actionTypes from '../ActionTypes';
import InitialState from '../InitialState';


/**
 * This reducer handles updating the slice of state which represents the status of our calls 
 * being made to refresh the users authentication tokens.
 *
 * @export refreshTokenReducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function refreshTokenReducer(state = InitialState.refreshTokenRequestStatus, action = {}) {
    switch (action.type) {
        case actionTypes.FETCHING_REFRESH_TOKEN:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.FETCHING_REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case actionTypes.FETCHING_REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        default:
            return state;
    }
}