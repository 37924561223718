import * as DashboardAPI from '../../../data/DashboardAPI.js';
import * as types from '../../../dataStore/ActionTypes.js';
import * as DateTimeUtils from '../../../utilities/DateTime/DateTimeUtils.js';
import * as UserLocalStorage from '../../../utilities/UserLocalStorage/UserLocalStorage';

export function loadTopViewedSeriesSuccess(topViewedSeries) {
	return {
		type: types.LOAD_TOP_VIEWED_SERIES_SUCCESS, data:
		{
			data: topViewedSeries
		}
	};
}

export function loadTopViewedSeriesError() {
	return {
		type: types.LOAD_TOP_VIEWED_SERIES_ERROR, data: {}
	};
}

export function loadTopViewedSeriesNoData() {
	return {
		type: types.LOAD_TOP_VIEWED_SERIES_NODATA, data: {}
	};
}

export function resetTopViewedSeries() {
	return {
	  type: types.RESET_TOP_VIEWED_SERIES
	};
  }

export function getTopViewedSeriesData() {
	return (dispatch, getState) => {
		let userData = getState().userData;

		retrieveData(dispatch, userData);
	};
}

function retrieveData(dispatch, userData) {
	let data = UserLocalStorage.getSpecificDataForUserFromSpecificCustomerAnalytics(userData.userId, userData.selectedCustId, "topViewedSeries");

	if (data === null) {
		let range = DateTimeUtils.getRangeForPreviousMonth();

		fetchTopViewedSeriesData(dispatch, userData, range.start, range.end);
	} else {
		dispatch(loadTopViewedSeriesSuccess(data));
	}
}

export function fetchTopViewedSeriesData(dispatch, userData, startDate, endDate) {
	return DashboardAPI.getDataWithDateRange('/Series/TopViewed', startDate, endDate, userData.accessToken, userData.selectedCustId)
		.then(topViewedSeries => {
			if (hasData(topViewedSeries)) {
				let tableData = buildData(topViewedSeries, startDate, userData.selectedCustName);
				updateLocalStorage(userData.userId, userData.selectedCustId, tableData);
				dispatch(loadTopViewedSeriesSuccess(tableData));
			}
			else {
				dispatch(loadTopViewedSeriesNoData());
			}
		})
		.catch(error => {
			dispatch(loadTopViewedSeriesError());
		});
}

function hasData(topViewedSeries) {
	return topViewedSeries.Result.length > 0 && topViewedSeries.Result[0].series.length > 0;
}

function buildData(topViewedSeries, startDate, libraryName) {
	let dateRange = DateTimeUtils.formatForDisplay(startDate);

	return {
		tableData: topViewedSeries.Result[0].series,
		title: `Top Series in ${dateRange}`,
		subTitle: '',
		csvHeader: `Top Series in ${dateRange}`,
		csvFileName: `${libraryName} Top Series ${dateRange}`
	};
}

function updateLocalStorage(userId, selectedCustId, topViewedSeries) {
	let localStorageData = {
		topViewedSeries
	};

	UserLocalStorage.setSpecificDataToSpecificCustomerAnalytics(userId, selectedCustId, localStorageData);
}

export function generateTopViewedSeriesCSV() {
    return (dispatch, getState) => {
		let state = getState();

		let csvData = state.topViewedSeries;

		if(csvData && csvData.data)
		{
			let libraryName = state.userData.selectedCustName;
			let payload = generateCsvPayload(libraryName, csvData.data);

			DashboardAPI.generateCsvReport('/BasicCSV', csvData.data.csvFileName, payload, state.userData.userToken);
		}
    };
}

export function generateCsvPayload(libraryName, csvData)
{
    let columnHeaders = getColumnHeaders();
    let rowData = getRowData(csvData.tableData);

    return {
        LibraryName: libraryName,
        ChartHeader: csvData.csvHeader,
        FileName: csvData.csvFileName,
        ColumnHeaders: columnHeaders,
        RowData: rowData,
    };
}

function getColumnHeaders()
{
    return ["Series","Author","Count"];
}

function getRowData(seriesData)
{
    return seriesData.map(x => [x.series,x.seriesAuthor,x.count.toString()])
}
