import * as ActionTypes from '../../../dataStore/ActionTypes.js';
import InitialState from '../../../dataStore/InitialState';
import * as StatusIDs from '../../../data/DataStatusIDs';

export default function TotalFeatureUsageReducer(state =  InitialState.totalFeatureUsage,  action = {}) {
  switch (action.type) {
    case ActionTypes.LOAD_TOTAL_FEATURE_USAGE_SUCCESS:
      return Object.assign({}, state, {status :StatusIDs.SUCCESS, ...action.data});
    case ActionTypes.LOAD_TOTAL_FEATURE_USAGE_ERROR:
        return Object.assign({}, state,{status :StatusIDs.ERROR, ...action.data});
    case ActionTypes.LOAD_TOTAL_FEATURE_USAGE_NODATA:
        return Object.assign({}, state,{status :StatusIDs.NODATA, ...action.data});
    case ActionTypes.RESET_TOTAL_FEATURE_USAGE:
        return Object.assign({}, state, { status: StatusIDs.LOADING, data: null });
    default:
      return state;

  }
}
