import * as ActionTypes from './dataStore/ActionTypes';
import InitialState from './dataStore/InitialState';

export default function FeatureFlagsReducer(state = InitialState.featureFlags, action = {}) {
  switch (action.type) {
    case ActionTypes.LOAD_FEATURE_FLAGS:
      if (Object.keys(state.data).length > 0 && state.data !== null) {
        let newFlags = Object.assign(state.data, { ...action.data.data });
        return Object.assign({}, state, { data: newFlags });
      }
      else
        return state;
    default:
      return state;
  }
}
