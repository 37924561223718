import * as Types from '../ActionTypes';

export function setUserData(userData) {
    return {
        type: Types.SET_USER_DATA,
        userData
    };
}

export function refreshUserTokens(refreshData) {
    return {
        type: Types.REFRESH_USER_TOKENS,
        refreshData
    };
}
