import * as DashboardAPI from '../../../data/DashboardAPI.js';
import * as types from '../../../dataStore/ActionTypes.js';
import { filterOutFirstDataSetIfEmpty, createChartDataForTotalFeatureUsage } from '../../../utilities/Charts/ChartUtils.js';
import * as DateTimeUtils from '../../../utilities/DateTime/DateTimeUtils.js';
import * as UserLocalStorage from '../../../utilities/UserLocalStorage/UserLocalStorage';

export function loadViewsOverTimeSuccess(viewsOverTime) {
  return {
    type: types.LOAD_VIEWS_OVER_TIME_SUCCESS, data:
    {
      data: viewsOverTime
    }
  };
}

export function loadViewsOverTimeError() {
  return { type: types.LOAD_VIEWS_OVER_TIME_ERROR, data: {} };
}

export function loadViewsOverTimeNoData() {
  return { type: types.LOAD_VIEWS_OVER_TIME_NODATA, data: {} };
}

export function resetViewsOverTime() {
  return { type: types.RESET_VIEWS_OVER_TIME };
}

export function getViewsOverTimeData() {
  return (dispatch, getState) => {
    let userData = getState().userData;

    retrieveData(dispatch, userData);
  };
}

function retrieveData(dispatch, userData) {
  let data = UserLocalStorage.getSpecificDataForUserFromSpecificCustomerAnalytics(userData.userId, userData.selectedCustId, "viewsOverTime");

  if (data === null) {
    let range = DateTimeUtils.getRangeStartOfPreviousTwoYearToPreviousMonth();

    fetchViewsOverTimeData(dispatch, userData, range.start, range.end);
  } else {
    dispatch(loadViewsOverTimeSuccess(data));
  }
}

export function fetchViewsOverTimeData(dispatch, userData, startDate, endDate) {
  return DashboardAPI.getDataWithDateRange('/views/total', startDate, endDate, userData.accessToken, userData.selectedCustId)
    .then(viewsOverTime => {
      if(hasData(viewsOverTime))
      {
        let data = buildData(viewsOverTime, startDate, endDate, userData.selectedCustName);
        updateLocalStorage(userData.userId, userData.selectedCustId, data);
        dispatch(loadViewsOverTimeSuccess(data));
      }
      else
      {
        dispatch(loadViewsOverTimeNoData());
      }
    })
    .catch(error => {
      dispatch(loadViewsOverTimeError());
    });
}

function hasData(viewsOverTime)
{
  return viewsOverTime.Result.length > 0 && viewsOverTime.Result.map(x => x.count).reduce((prev, next) => prev + next) > 0
}

function buildData(viewsOverTime, startDate, endDate, libraryName) {
  let chart = filterOutFirstDataSetIfEmpty(createChartDataForTotalFeatureUsage(viewsOverTime.Result));
  startDate = new Date(chart.chartData.datasets[0].label, 0, 1);

  let dateRange = `${DateTimeUtils.formatAsFullDate(startDate)} - ${DateTimeUtils.formatAsFullDate(endDate)}`;

  chart.title = `Views from ${dateRange}`;
  chart.csvFileName = `${libraryName} NoveList Select Views ${DateTimeUtils.formatWithMonthAbbreviated(startDate)} to ${DateTimeUtils.formatWithMonthAbbreviated(endDate)}`;
  chart.csvHeader = `Views from ${dateRange}`;

  return chart;
}

function updateLocalStorage(userId, custId, viewsOverTime) {
  let localStorageData = {
    viewsOverTime
  };

  UserLocalStorage.setSpecificDataToSpecificCustomerAnalytics(userId, custId, localStorageData);
}

export function generateViewsOverTimeCSV() {
  return (dispatch, getState) => {
  let state = getState();

  let csvData = state.viewsOverTime;

  if(csvData && csvData.data)
  {
    let libraryName = state.userData.selectedCustName;
    let payload = generateCsvPayload(libraryName, csvData.data);

    DashboardAPI.generateCsvReport('/BasicCSV', csvData.data.csvFileName, payload, state.userData.userToken);
  }
  };
}

export function generateCsvPayload(libraryName, csvData)
{
  let columnHeaders = getColumnHeaders();
  let rowData = getRowData(csvData.chartData);

  return {
      LibraryName: libraryName,
      ChartHeader: csvData.csvHeader,
      FileName: csvData.csvFileName,
      ColumnHeaders: columnHeaders,
      RowData: rowData,
  };
}

function getColumnHeaders()
{
  return [
    "", "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
}

function getRowData(chartData)
{
  return chartData.datasets.map(x => getRowForDataSet(x));
}

function getRowForDataSet(dataSet)
{
  let result = [dataSet.label.replace(" Year To Date", "")]

  dataSet.data.map(x => result.push(x.toString()));

  return result;
}
