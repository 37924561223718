import * as DashboardAPI from '../../../data/DashboardAPI.js';
import * as types from '../../../dataStore/ActionTypes.js';
import * as DateTimeUtils from '../../../utilities/DateTime/DateTimeUtils.js';
import * as UserLocalStorage from '../../../utilities/UserLocalStorage/UserLocalStorage';

export function loadMonthlyTotalUsageByFeatureSuccess(monthlyTotalUsageByFeature) {
    return {
        type: types.MONTHLY_TOTAL_USAGE_BY_FEATURE_SUCCESS,
        data: {
            data: monthlyTotalUsageByFeature
        }
    };
}

export function loadMonthlyTotalUsageByFeatureError() {
    return {
        type: types.MONTHLY_TOTAL_USAGE_BY_FEATURE_ERROR,
        data: {}
    };
}

export function resetMonthlyTotalUsageByFeature() {
    return { type: types.RESET_MONTHLY_TOTAL_USAGE_BY_FEATURE };
}

export function generateMonthlyTotalUsageByFeatureDataCSV() {
    return (dispatch, getState) => {
        let userData = getState().userData;

        let libraryName = userData.selectedCustName;

        return retrieveData(dispatch, userData, libraryName)
            .then(csvData => {
                if (csvData) {
                    let payload = generateCsvPayload(libraryName, csvData);

                    DashboardAPI.generateCsvReport('/BasicCSV', csvData.csvFileName, payload, userData.userToken);
                }
            });
    };
}

export function generateCsvPayload(libraryName, csvData)
{
    let columnHeaders = getColumnHeaders(csvData.featureClickReports);
    let rowData = getRowData(csvData.featureClickReports);

    return {
        LibraryName: libraryName,
        ChartHeader: csvData.csvHeader,
        FileName: csvData.csvFileName,
        ColumnHeaders: columnHeaders,
        RowData: rowData,
    };
}

function getColumnHeaders(featureClickReports)
{
    var headers = featureClickReports.map(x => DateTimeUtils.formatWithSlashes(x.reportDate));
    headers.unshift('');

    return headers;
}

function getRowData(featureClickReports)
{
    let labels = getLabels(featureClickReports);

    return generateDataRowsForLabels(featureClickReports, labels)
}

function generateDataRowsForLabels(featureClickReports, labels)
{
    let rows = [];

    var i;
    for (i = 0; i < labels.length; i++) {
        rows.push(generateRowForLabel(labels[i], featureClickReports));
    }

    return rows;
}

function generateRowForLabel(label, featureClickReports) {
    let newRow = [label];

    var i;
    for (i = 0; i < featureClickReports.length; i++) {
        newRow.push(getCountofLabelForFeature(featureClickReports[i], label));
    }

    return newRow;
}

function getCountofLabelForFeature(featureReport, label)
{
    let featureEntry = featureReport.features.find(x => x.featureName === label);

    if(featureEntry)
    {
        return featureEntry.count.toString();
    }
    else
    {
        return "0";
    }
}

function getLabels(featureClickReports)
{
    let labels = [];

    var i;
    for (i = 0; i < featureClickReports.length; i++) {
        labels = labels.concat(featureClickReports[i].features.map(x => x.featureName))
    }

    return [...new Set(labels)];
}

export function setUpMonthlyTotalUsageByFeatureData() {
    return (dispatch, getState) => {
        let userData = getState().userData;

        let libraryName = userData.selectedCustName;

        return retrieveData(dispatch, userData, libraryName);
    };
}


export function retrieveData(dispatch, userData, libraryName) {
    let data = UserLocalStorage.getSpecificDataForUserFromSpecificCustomerAnalytics(userData.userId, userData.selectedCustId, "monthlyTotalUsageByFeature");

    if (data === null) {
        let range = DateTimeUtils.getRangeForPreviousNMonths(13);
        return fetchMonthlyTotalUsageByFeatureData(dispatch, userData, range.start, range.end, libraryName);
    } else {
        dispatch(loadMonthlyTotalUsageByFeatureSuccess(data));
        return Promise.resolve(data);
    }
}

export function fetchMonthlyTotalUsageByFeatureData(dispatch, userData, startDate, endDate, libraryName) {
    return DashboardAPI.getDataWithDateRange('/clicks/byfeature', startDate, endDate, userData.accessToken, userData.selectedCustId)
        .then(monthlyTotalUsageByFeature => {
            let chartData = buildData(monthlyTotalUsageByFeature, startDate, endDate, libraryName);

            if (chartData){
                updateLocalStorage(userData.userId, userData.selectedCustId, chartData);
                dispatch(loadMonthlyTotalUsageByFeatureSuccess(chartData));
            }else {
                dispatch(loadMonthlyTotalUsageByFeatureError());
            }
            return chartData;
        })
        .catch(error => {
            dispatch(loadMonthlyTotalUsageByFeatureError());
            return null;
        });
}

function buildData(monthlyTotalUsageByFeature, startDate, endDate, libraryName) {
    if (!monthlyTotalUsageByFeature.Result) {return null};

    let result = {
        featureClickReports: monthlyTotalUsageByFeature.Result,
        csvHeader: "Breakdown of clicks from " +DateTimeUtils.formatAsFullDate(startDate) + " to " + DateTimeUtils.formatAsFullDate(endDate),
        csvFileName: libraryName +" Breakdown of NoveList Select clicks " +DateTimeUtils.formatForDisplay(startDate) + " to " + DateTimeUtils.formatForDisplay(endDate),
    }
    return result;
}

function updateLocalStorage(userId, selectedCustId, monthlyTotalUsageByFeature) {
    let localStorageData = {
        monthlyTotalUsageByFeature
    };

    UserLocalStorage.setSpecificDataToSpecificCustomerAnalytics(userId, selectedCustId, localStorageData);
}
