import InitialState from '../InitialState';
import rootReducer from '../defaultReducers/rootReducer';
import thunk from 'redux-thunk';
import {createStore, applyMiddleware} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { authMiddleware } from './authMiddleware';

/**
 * Wrapper for configuring and creating the our single Redux store. 
 * https://redux.js.org/api/createstore
 * @param {*} [initialState=InitialState]
 * @returns
 */
function configureStore(initialState = InitialState) {
    return createStore(
      rootReducer,
      initialState,
      composeWithDevTools(applyMiddleware(authMiddleware, thunk))
    );

}

export default configureStore;