import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { getSessionID, removeCookie } from './utilities/Cookies/cookieUtilities';
import { getUserDataById, userDataHasRequiredFields } from './utilities/Token/tokenUtils';
import { hasValidData, clearLocalStorageForUser } from './utilities/UserLocalStorage/UserLocalStorage';
import * as configuration from './configuration';
import * as MultiUserDropdownActions from './features/Menus/MultiUserDropDown/MultiUserDropDownActions';
import SplashPageLayout from './layouts/SplashPageLayout/SplashPageLayout';

export function renderApp() {
    let sessionId = getSessionID();
    let userData = getUserDataById(sessionId);

    if(shouldRenderApp(sessionId, userData)) {
        return renderWithData(userData);
    } else {
        return new Promise((resolve, reject) => {
            clearTempData();
            ReactDOM.render(<SplashPageLayout />, document.getElementById('root'));
            resolve();
        });
    }
}

/**
 * We should only render the app if we have a valid sessionId and
 * the userData has the required fields
 * @param {string} sessionId 
 * @param {object} userData 
 */
function shouldRenderApp(sessionId, userData)
{
    return sessionId && userDataHasRequiredFields(userData)
}

/**
 * Renders the application using the data supplied
 * Also cleans up the auth cookie and local storage
 * @param {object} userData 
 */
function renderWithData(userData)
{
    destroyTemporaryAuthCookie();
    clearLocalStorageIfExpired(userData.userId);
    return MultiUserDropdownActions.getChildSiteData(userData).then(data => {
        ReactDOM.render(<App userData={userData} custData={data} />, document.getElementById('root'));
    });
}

function clearLocalStorageIfExpired(userId) {
    let localStorageIsValid = hasValidData(userId);

    if (!localStorageIsValid) {
        clearLocalStorageForUser(userId);
    }
}

/**
 * Unauthorized users need to have their cookies removed, then redirect them to the login screen.
 */
function clearTempData() {
    destroySessionCookie();
    destroyTemporaryAuthCookie();
}

function destroyTemporaryAuthCookie() {
    removeCookie(configuration.tokenCookie);
}

function destroySessionCookie() {
    removeCookie(configuration.sessionCookie);
}