export const LOAD_TOTAL_CLICK_SUCCESS = 'LOAD_TOTAL_CLICK_SUCCESS';
export const LOAD_TOTAL_CLICK_ERROR = 'LOAD_TOTAL_CLICK_ERROR';
export const RESET_TOTAL_CLICK = 'RESET_TOTAL_CLICK';
export const LOAD_TOTAL_VIEW_SUCCESS = 'LOAD_TOTAL_VIEW_SUCCESS';
export const LOAD_TOTAL_VIEW_ERROR = 'LOAD_TOTAL_VIEW_ERROR';
export const RESET_TOTAL_VIEW = 'RESET_TOTAL_VIEW';
export const LOAD_TOTAL_FEATURE_USAGE_SUCCESS = 'LOAD_TOTAL_FEATURE_USAGE_SUCCESS';
export const LOAD_TOTAL_FEATURE_USAGE_ERROR = 'LOAD_TOTAL_FEATURE_USAGE_ERROR';
export const LOAD_TOTAL_FEATURE_USAGE_NODATA = 'LOAD_TOTAL_FEATURE_USAGE_NODATA';
export const RESET_TOTAL_FEATURE_USAGE = 'RESET_TOTAL_FEATURE_USAGE';
export const LOAD_VIEWS_OVER_TIME_SUCCESS = 'LOAD_VIEWS_OVER_TIME_SUCCESS';
export const LOAD_VIEWS_OVER_TIME_ERROR = 'LOAD_VIEWS_OVER_TIME_ERROR';
export const LOAD_VIEWS_OVER_TIME_NODATA = 'LOAD_VIEWS_OVER_TIME_NODATA';
export const RESET_VIEWS_OVER_TIME = 'RESET_VIEWS_OVER_TIME';
export const MONTHLY_TOTAL_USAGE_BY_FEATURE_SUCCESS = 'MONTHLY_TOTAL_USAGE_BY_FEATURE_SUCCESS';
export const MONTHLY_TOTAL_USAGE_BY_FEATURE_ERROR = 'MONTHLY_TOTAL_USAGE_BY_FEATURE_ERROR';
export const RESET_MONTHLY_TOTAL_USAGE_BY_FEATURE = 'RESET_MONTHLY_TOTAL_USAGE_BY_FEATURE';
export const LOAD_TOTAL_USAGE_BY_FEATURE_SUCCESS = 'LOAD_TOTAL_USAGE_BY_FEATURE_SUCCESS';
export const LOAD_TOTAL_USAGE_BY_FEATURE_ERROR = 'LOAD_TOTAL_USAGE_BY_FEATURE_ERROR';
export const LOAD_TOTAL_USAGE_BY_FEATURE_NODATA = 'LOAD_TOTAL_USAGE_BY_FEATURE_NODATA';
export const RESET_TOTAL_USAGE_BY_FEATURE = 'RESET_TOTAL_USAGE_BY_FEATURE';
export const LOAD_TOP_VIEWED_TITLES_SUCCESS = 'LOAD_TOP_VIEWED_TITLES_SUCCESS';
export const LOAD_TOP_VIEWED_TITLES_ERROR = 'LOAD_TOP_VIEWED_TITLES_ERROR';
export const LOAD_TOP_VIEWED_TITLES_NODATA = 'LOAD_TOP_VIEWED_TITLES_NODATA';
export const RESET_TOP_VIEWED_TITLES = 'RESET_TOP_VIEWED_TITLES';
export const LOAD_TOP_VIEWED_AUTHORS_SUCCESS = 'LOAD_TOP_VIEWED_AUTHORS_SUCCESS';
export const LOAD_TOP_VIEWED_AUTHORS_ERROR = 'LOAD_TOP_VIEWED_AUTHORS_ERROR';
export const LOAD_TOP_VIEWED_AUTHORS_NODATA = 'LOAD_TOP_VIEWED_AUTHORS_NODATA';
export const RESET_TOP_VIEWED_AUTHORS = 'RESET_TOP_VIEWED_AUTHORS';
export const LOAD_SYSTEM_MESSAGE_SUCCESS = 'LOAD_SYSTEM_MESSAGE_SUCCESS';
export const LOAD_SYSTEM_MESSAGE_ERROR = 'LOAD_SYSTEM_MESSAGE_ERROR';
export const LOAD_TOP_VIEWED_SERIES_SUCCESS = 'LOAD_TOP_VIEWED_SERIES_SUCCESS';
export const LOAD_TOP_VIEWED_SERIES_ERROR = 'LOAD_TOP_VIEWED_SERIES_ERROR';
export const LOAD_TOP_VIEWED_SERIES_NODATA = 'LOAD_TOP_VIEWED_SERIES_NODATA';
export const RESET_TOP_VIEWED_SERIES = 'RESET_TOP_VIEWED_SERIES';
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const SET_DATA_EXPIRATION_DATE = 'SET_DATA_EXPIRATION_DATE';
export const SET_USER_DATA = 'SET_USER_DATA';
export const REFRESH_USER_TOKENS = 'REFRESH_USER_TOKENS';
export const FETCHING_REFRESH_TOKEN = 'FETCHING_REFRESH_TOKEN';
export const FETCHING_REFRESH_TOKEN_SUCCESS = 'FETCHING_REFRESH_TOKEN_SUCCESS';
export const FETCHING_REFRESH_TOKEN_FAILURE = 'FETCHING_REFRESH_TOKEN_FAILURE';
export const LOAD_FEATURE_FLAGS = 'LOAD_FEATURE_FLAGS';
export const LOAD_MULTI_USER_DROPDOWN_SUCCESS = 'LOAD_MULTI_USER_DROPDOWN_SUCCESS';
export const UPDATE_SELECTED_CUST = 'UPDATE_SELECTED_CUST';