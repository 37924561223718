import * as DashboardAPI from '../../../data/DashboardAPI.js';
import * as types from '../../../dataStore/ActionTypes.js';
import { mapSeverityToCssClass } from '../../../utilities/Branding/GetBranding.js';

export function loadSystemMessageSuccess(systemMessage) {
  return {
    type: types.LOAD_SYSTEM_MESSAGE_SUCCESS, data:
    {
      data: systemMessage
    }
  };
}

export function loadSystemMessageError() {
  return { type: types.LOAD_SYSTEM_MESSAGE_ERROR, data: {} };
}

export function getSystemMessageData() {
  return (dispatch, getState) => {
    let userData = getState().userData;

    fetchSystemMessageData(dispatch, userData);
  };
}

export function fetchSystemMessageData(dispatch, userData) {
  return DashboardAPI.getSystemSettings(userData.accessToken)
    .then(systemMessage => {
      
        let data = buildData(systemMessage);

        dispatch(loadSystemMessageSuccess(data));
    })
    .catch(error => {
      dispatch(loadSystemMessageError());
    });
}

function buildData(systemMessage) {
  return {
    message: systemMessage.SystemMessage,
    displayClass: mapSeverityToCssClass(systemMessage.SystemMessageSeverity)
  }
}

