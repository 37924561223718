import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import HeaderWithLine from '../../components/headers/HeaderWithLine';
import twitterLogo from '../../assets/img/social/twitter-logo.png';
import facebookLogo from '../../assets/img/social/facebook-logo.png';
import linkedinLogo from '../../assets/img/social/linked-in-logo.png';
import youtubeLogo from '../../assets/img/social/youtube-logo.png';
import './SplashPage.css';
import TwitterFeed from '../Twitter/TwitterFeed';

class SplashPageSocialSection extends Component {
  render() {
    return (
        <div>
          <Col>
            <HeaderWithLine headerText="Let's get social" />
          </Col>
          <Row className="splash-page-section-body">
            <Col>
                <Row>
                    <Col>
                        <div className='splash-page-sub-header'>Find and follow us on your favorite social platforms.</div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <a href="https://www.facebook.com/EBSCONoveList" target="_blank" rel="noopener noreferrer">
                            <img height='100' src={facebookLogo} alt="Novelist Facebook" />
                        </a>
                    </Col>
                    <Col>
                        <a href="https://twitter.com/NoveListRA" target="_blank" rel="noopener noreferrer">
                            <img height='100' src={twitterLogo} alt="Novelist Twitter" />
                        </a>
                    </Col>
                    <Col>
                        <a href="https://www.youtube.com/user/EBSCONoveList" target="_blank" rel="noopener noreferrer">
                            <img height='100' src={youtubeLogo} alt="Novelist Youtube" />
                        </a>
                    </Col>
                    <Col>
                        <a href="https://www.linkedin.com/showcase/novelistra" target="_blank" rel="noopener noreferrer">
                            <img height='100'src={linkedinLogo} alt="Novelist LinkedIn" />
                        </a>
                    </Col>
                </Row>
            </Col>
                
            <Col md='6'>
                <TwitterFeed />
            </Col>
          </Row>
        </div>
    );
  }
}

export default SplashPageSocialSection;