import * as DashboardAPI from '../../data/DashboardAPI'

function baseEvent(userData, eventName) {
    return {
        project: "Dashboard",
        event: {
            user_id: userData.userId,
            device_id: 'nov.' +userData.custId,
            event_type: eventName,
            event_properties: {
                custName: userData.custName,
                custId: userData.custId,
                selected_custID: userData.selectedCustId,
                selected_custName: userData.selectedCustName
            }
        }
    }
}

export function logPageLoad(location)
{
    return (dispatch, getState) => {
        let userData = getState().userData;
        
        var eventName = getLogEventForRoute(location);

        if(eventName)
        {
            var eventData = baseEvent(userData, eventName);
            DashboardAPI.logEvent(eventData, userData.accessToken);
        }
    };
}

function getLogEventForRoute(location)
{
    let dictionary = {
        "/dashboard": 'Reader Engagement - visited',
        "/help": 'Help Page - visited',
        "/readerinsights": 'Reader Insights - visited'
    }

    return dictionary[location];
} 

export function logUserLogin(userData)
{
    var eventData = baseEvent(userData, 'Dashboard - authenticated');

    DashboardAPI.logEvent(eventData, userData.accessToken);
}

export function logDownload(chartType, affordanceUsed)
{
    return (dispatch, getState) => {
        let userData = getState().userData;

        let eventData = baseEvent(userData, 'Download - clicked');

        eventData.event.event_properties.chart_type = chartType;
        eventData.event.event_properties.affordance_used = affordanceUsed;

        DashboardAPI.logEvent(eventData, userData.accessToken);
    }
}

export function logUserSelected(userData, selectedCustId, selectedCustName)
{
    var eventData = baseEvent(userData, 'Org Picker - clicked');

    eventData.event.event_properties.selected_custID = selectedCustId;
    eventData.event.event_properties.selected_custName = selectedCustName;
    eventData.event.event_properties.previous_custID = userData.selectedCustId;
    eventData.event.event_properties.previous_custName = userData.selectedCustName;

    DashboardAPI.logEvent(eventData, userData.accessToken);
}