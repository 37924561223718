import React from 'react';
import { DropdownItem, Row, Col } from 'reactstrap';
import './MultiUserDropDown.css';

const MultiUserDropdownItemHeader = (props) => {
    return (
        <DropdownItem header>
            <Row className="user-dropdown-item">
                <Col xs="12" md="12" >
                    <span className='user-dropdown-item-name'>{props.headerText}</span>
                </Col>
            </Row>
        </DropdownItem>
    );
}

export default MultiUserDropdownItemHeader;