import { hexToRgba } from '@coreui/coreui-pro/dist/js/coreui-utilities';
import { GetBrandingColorByName, LineChartColorScheme } from '../Branding/GetBranding';

export function calculateHeight(rowCount, heightPerRow)
{
    return rowCount * heightPerRow;
}

export function buildChartData(dataSetTemplate, chartData)
{
    const ret = {
        labels: chartData.labels,
        datasets: chartData.datasets.map(set => buildFromDataTemplate(dataSetTemplate, set))
    };

    return ret;
}

export function buildFromDataTemplate(dataSetTemplate, set)
{
    let copy = Object.assign({}, dataSetTemplate);
    let branding = GetBrandingColorByName(set.branding);

    copy.data = set.data;
    copy.label = set.label;
    copy.backgroundColor = hexToRgba(branding, 10);
    copy.borderColor = branding;

    return copy;
}

export function calculateStepSize(chartData)
{
    let maxOfEachSet = chartData.datasets.map(set => Math.max.apply(Math, set.data));
    let max = Math.max.apply(Math, maxOfEachSet);
    let numberLength = max.toString().length;
    let divisor = parseInt('1' + '0'.repeat(numberLength - 1));
    let reduced = max / divisor;
    let rounded = Math.ceil(reduced);

    if(rounded <= 2)
    {
        return (divisor / 5);
    }
    else if(rounded <= 5)
    {
        return (divisor / 2);
    }
    else
    {
        return divisor;
    }
}

export function createChartDataObject(APIobject, chartTitle, chartSubtitle){

    let chartData = {
        title: chartTitle,
        subtitle: chartSubtitle,
        chartData: {
          labels: APIobject.xValues,
          datasets: APIobject.dataSets.map((current, index) => {
                return {
                    label: current.label,
                    backgroundColor: hexToRgba(GetBrandingColorByName(LineChartColorScheme[index % LineChartColorScheme.length]), 10),
                    borderColor: GetBrandingColorByName(LineChartColorScheme[index % LineChartColorScheme.length]),
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 2,
                    data: current.values,
                    branding: LineChartColorScheme[index % LineChartColorScheme.length]
                }
          })
        }
      };

      return chartData;
}

export function createChartDataForTotalFeatureUsage(APIobject, title, subtitle, csvFileName, csvHeader)
{
    let initialResult = createSplitByYearChartDataObject(APIobject, title, subtitle);

    initialResult.csvFileName = csvFileName;
    initialResult.csvHeader = csvHeader;

    if(initialResult.chartData.datasets.length > 0)
    {
        let lastDataSetIndex = initialResult.chartData.datasets.length - 1;

        initialResult.chartData.datasets[lastDataSetIndex].label += " Year To Date";
    }
    return initialResult;
}

export function createSplitByYearChartDataObject(APIobject, title, subtitle)
{
    let newAPIObject = {
        xValues: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
        dataSets: []
    };

    let dataSetIndex = 0;
    let currentYear = 0;
    let dataSet = null;

    for(dataSetIndex; dataSetIndex < APIobject.length; dataSetIndex++)
    {
        let oDataSet = APIobject[dataSetIndex];
        let value = oDataSet.count;

        let year = new Date(APIobject[dataSetIndex].reportDate).getFullYear();
        let month = new Date(APIobject[dataSetIndex].reportDate).getMonth();

        if(year !== currentYear)
        {
            if(dataSet != null)
            {
                newAPIObject.dataSets.push(dataSet);
            }

            currentYear = year;
            dataSet = {
                label: year.toString(),
                values: []
            };

            let i = 0;
            for(i; i < month; i++)
            {
                dataSet.values.push(0);
            }
        }
        if(dataSet != null)
        {
            dataSet.values.push(value);
        }
    }

    if(dataSet != null)
    {
        newAPIObject.dataSets.push(dataSet);
    }

    return createChartDataObject(newAPIObject, title, subtitle);
}

export function createInvertedChartDataObject(APIobject, title, subtitle)
{
    let newAPIObject = {
        xValues: APIobject.features.map(x => x.featureName),
        dataSets: [{
            label: '',
            values: APIobject.features.map(x => x.count)
        }]
    };

    return createChartDataObject(newAPIObject, title, subtitle);
}

export function filterOutFirstDataSetIfEmpty(chart){
    if (chart.chartData.datasets[0].data.every(item => item === 0)){
        chart.chartData.datasets.splice(0, 1);
    }

    return chart;
}