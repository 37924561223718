import React from "react";
import { Col, Row } from "reactstrap";
import { splashPageWidgetUrl } from "../../configuration";
import useAddScript from "../../utilities/useAddScript";
import './HomeCarousel.css';


const HomeCarousel = () => {
    useAddScript(splashPageWidgetUrl);
    return (
        <Row>
            <Col className="home-carousel">
                <div className="libraryaware_widget_c27d6863eb7645718f87c1dd3ee7dfcd"></div>
            </Col>
        </Row>
    );
}

export default HomeCarousel;